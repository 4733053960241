var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colours, customFonts, fontSizes, layoutSizes } from "../../constants/css";
var TabContainer = styled.div.withConfig({
  displayName: "Tabs__TabContainer",
  componentId: "sc-nz2i5f-0"
})(["background:", ";"], colours.qantasIceWhite);
var TabNavList = styled.ul.withConfig({
  displayName: "Tabs__TabNavList",
  componentId: "sc-nz2i5f-1"
})(["display:flex;padding:0;list-style:none;margin:0;background:", ";", ""], colours.qantasWhite, function (props) {
  return props.centre && "\n    justify-content: center;\n  ";
});
var TabNavListItem = styled.li.withConfig({
  displayName: "Tabs__TabNavListItem",
  componentId: "sc-nz2i5f-2"
})(["font-size:", ";padding:1rem 2rem;text-align:center;border-top:1px solid ", ";border-right:1px solid ", ";cursor:pointer;background-color:", ";position:relative;", " &:last-child{border-top:1px solid ", ";border-right:1px solid ", ";}&:first-child{border-top:1px solid ", ";border-left:1px solid ", ";}@media only screen and (min-width:", "){padding:1rem 3rem;}"], fontSizes.large, colours.qantasLightGrey, colours.qantasLightGrey, colours.qantasWhite, function (props) {
  return props.active && "\n    background-color: ".concat(colours.qantasIceWhite, ";\n    font-family: ").concat(customFonts.ciutadellaMed, ";\n\n    &::before {\n      content: \"\";\n      display: inline-block;\n      position: absolute;\n      height: 4px;\n      width: 100%;\n      background: ").concat(colours.qantasRed, ";\n      top: 0;\n      left: 0;\n    }\n  ");
}, function (props) {
  return props.active ? 'transparent' : colours.qantasLightGrey;
}, function (props) {
  return props.active ? 'transparent' : colours.qantasLightGrey;
}, function (props) {
  return props.active ? 'transparent' : colours.qantasLightGrey;
}, function (props) {
  return props.active ? 'transparent' : colours.qantasLightGrey;
}, layoutSizes.desktopWidth);
var TabContent = styled.div.withConfig({
  displayName: "Tabs__TabContent",
  componentId: "sc-nz2i5f-3"
})(["padding:1rem;@media only screen and (min-width:", "){padding:45px 55px;}"], layoutSizes.desktopWidth);
var Tabs = function Tabs(_ref) {
  var children = _ref.children,
    centre = _ref.centre,
    initialTab = _ref.initialTab;
  var tabLabels = children.map(function (_ref2) {
    var label = _ref2.props.label;
    return label;
  });
  var defaultTab = tabLabels.includes(initialTab) ? initialTab : tabLabels[0];
  var _useState = useState(defaultTab),
    activeTab = _useState[0],
    setActiveTab = _useState[1];
  useEffect(function () {
    if (initialTab === activeTab || !tabLabels.includes(initialTab)) {
      return;
    }
    setActiveTab(initialTab);
  }, [initialTab]);
  return __jsx(TabContainer, null, __jsx(TabNavList, {
    centre: centre
  }, tabLabels.map(function (label, key) {
    return __jsx(TabNavListItem, {
      key: key,
      onClick: function onClick() {
        return setActiveTab(label);
      },
      active: label === activeTab,
      tabIndex: "0",
      role: "button",
      "aria-pressed": "false",
      onKeyPress: function onKeyPress() {
        return setActiveTab(label);
      }
    }, label);
  })), __jsx(TabContent, null, children.map(function (_ref3) {
    var _ref3$props = _ref3.props,
      label = _ref3$props.label,
      tab = _ref3$props.children;
    if (label !== activeTab) {
      return null;
    }
    return tab;
  })));
};
export default Tabs;