var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Spinner } from "./index";
export var LoadingSection = styled.div.withConfig({
  displayName: "Loader__LoadingSection",
  componentId: "sc-ld5roo-0"
})(["height:", ";"], function (props) {
  return props.fullPage ? '25vh' : 'inherit';
});
export var Loading = styled(Spinner).withConfig({
  displayName: "Loader__Loading",
  componentId: "sc-ld5roo-1"
})(["position:absolute;z-index:1;top:50%;left:47%;transform:translate(-50%,-50%);"]);
var Loader = function Loader(_ref) {
  var fullPage = _ref.fullPage;
  return __jsx(LoadingSection, {
    fullPage: fullPage
  }, __jsx(Loading, null));
};
Loader.defaultProps = {
  fullPage: false
};
export default Loader;