import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["href", "text", "isExternal", "clickEvent"];
import React from "react";
var __jsx = React.createElement;
import Link from 'next/link';
import styled from 'styled-components';
import { colours } from "../../constants/css";
var arrow = "/static/link-arrow.svg";
export var Anchor = styled.a.withConfig({
  displayName: "ArrowLink__Anchor",
  componentId: "sc-5efb30-0"
})(["display:inline-block;color:", ";cursor:pointer;text-decoration:underline;"], colours.qantasRed);
export var Arrow = styled.i.withConfig({
  displayName: "ArrowLink__Arrow",
  componentId: "sc-5efb30-1"
})(["display:inline-block;background:url(", ");background-repeat:no-repeat;height:7px;width:11px;"], arrow);
var ArrowLink = function ArrowLink(_ref) {
  var _ref$href = _ref.href,
    href = _ref$href === void 0 ? '' : _ref$href,
    text = _ref.text,
    isExternal = _ref.isExternal,
    clickEvent = _ref.clickEvent,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (isExternal) {
    return __jsx(Anchor, _extends({
      href: href,
      target: "_blank",
      rel: "noopener noreferrer"
    }, rest), text, " ", __jsx(Arrow, null));
  }
  return __jsx(Link, _extends({
    href: href,
    passHref: true
  }, rest), __jsx(Anchor, {
    onClick: clickEvent
  }, text, " ", __jsx(Arrow, null)));
};
ArrowLink.defaultProps = {
  isExternal: false
};
export default ArrowLink;