import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["SHO_WEB"];
/** @format */

import { isNumber } from 'lodash';
import { CHANNEL, SESSION_STORAGE_KEY_NAMES } from "../constants/app";
import isBrowser from "./isBrowser";

/**
 * Record how many times a page has been visited by the user,
 * and if the toaster advertising tray message of a page is dismissed.
 *
 * It's for the feature that to circulate toasters (messages) when a user visits
 * the same page in different times.
 *
 * Storing the message rotating index in sessionStorage instead of
 * the state of a component (_app) is because if the user
 * clicks back/forward on a browser, it will lose the track of the component state
 * */

var getCurrentPath = function getCurrentPath(path) {
  if (isBrowser && window.sessionStorage.getItem(path)) {
    return {
      numOfTimes: JSON.parse(window.sessionStorage.getItem(path)).numOfTimes,
      isDismissed: JSON.parse(window.sessionStorage.getItem(path)).isDismissed
    };
  }
  return {
    numOfTimes: 0,
    isDismissed: false
  };
};

// Set the number of times a page being viewed and
// if the toasters of a page being dismissed within one session
var setToasterIndex = function setToasterIndex(path) {
  var _getCurrentPath = getCurrentPath(path),
    numOfTimes = _getCurrentPath.numOfTimes,
    isDismissed = _getCurrentPath.isDismissed;
  if (isNumber(numOfTimes)) {
    var value = JSON.stringify({
      numOfTimes: numOfTimes + 1,
      isDismissed: isDismissed
    });
    isBrowser && window.sessionStorage.setItem(path, value);
  } else {
    // First time navigating to this path
    isBrowser && window.sessionStorage.setItem(path, JSON.stringify({
      numOfTimes: 0,
      isDismissed: false
    }));
  }
};

// determine which toaster message to show at the time the user visit the page
var getToasterIndex = function getToasterIndex(path, toastersLength) {
  var index = getCurrentPath(path).numOfTimes;
  // if there is at least one toaster message
  if (isNumber(toastersLength) && isNumber(index) && toastersLength !== 0) {
    if (index < toastersLength) {
      return index;
    }
    return index % toastersLength;
  }
  return 0;
};
var dismissToaster = function dismissToaster(path) {
  var pathObj = getCurrentPath(path);
  isBrowser && window.sessionStorage.setItem(path, JSON.stringify({
    numOfTimes: pathObj.numOfTimes,
    isDismissed: true
  }));
};

// This function will be used in app's webview to identify the landing page of it.
// The path should include 'channel' parameter
var setMobileAppLandingPage = function setMobileAppLandingPage(path) {
  if (path.indexOf('channel=') > -1) {
    isBrowser && window.sessionStorage.setItem(SESSION_STORAGE_KEY_NAMES.MOBILE_APP_LANDING_PAGE, path);
  }
};
var getMobileAppLandingPage = function getMobileAppLandingPage() {
  return isBrowser && window.sessionStorage.getItem(SESSION_STORAGE_KEY_NAMES.MOBILE_APP_LANDING_PAGE);
};
var isQChannel = function isQChannel(channel) {
  var SHO_WEB = CHANNEL.SHO_WEB,
    rest = _objectWithoutProperties(CHANNEL, _excluded);
  var qChannels = Object.values(rest);
  return channel && qChannels.some(function (item) {
    return item.toLowerCase() === channel.toLowerCase();
  });
};
var setChannel = function setChannel(channel) {
  return isBrowser && isQChannel(channel) && window.sessionStorage.setItem(SESSION_STORAGE_KEY_NAMES.MOBILE_APP_CHANNEL, channel.toLowerCase());
};

// default to browser (CHANNEL.SHO_WEB), any other channel means an app webview
var getChannel = function getChannel() {
  if (isBrowser) {
    var c = window.sessionStorage.getItem(SESSION_STORAGE_KEY_NAMES.MOBILE_APP_CHANNEL);
    if (isQChannel(c)) {
      return c;
    }
  }
  return CHANNEL.SHO_WEB;
};
var isChannelForBrowser = function isChannelForBrowser() {
  return getChannel() === CHANNEL.SHO_WEB;
};

/**
 * Returns the loginSlug object from sessionStorage of browser
 * @returns {Object | null} containing slug and fromPlugin if found else null
 */
var getLoginSlugFromSessionStorage = function getLoginSlugFromSessionStorage() {
  var loginSlug = window.sessionStorage.getItem(SESSION_STORAGE_KEY_NAMES.LOGIN_SLUG_NAME);
  if (loginSlug) {
    return JSON.parse(loginSlug);
  }
  return null;
};

/**
 * Stores the login slug object to sessionStorage of browser
 * @param {Object} loginSlug - Contains the slug and fromPlugin
 * @param {string} loginSlug.slug - contains the merchant id.
 * @param {string} loginSlug.fromPlugin - tells whether points prompter open the page using &fp=true query param
 */
var setLoginSlugToSessionStorage = function setLoginSlugToSessionStorage(loginSlug) {
  window.sessionStorage.setItem(SESSION_STORAGE_KEY_NAMES.LOGIN_SLUG_NAME, JSON.stringify(loginSlug));
};
export { setToasterIndex, getToasterIndex, dismissToaster, getCurrentPath, setMobileAppLandingPage, getMobileAppLandingPage, setChannel, getChannel, isChannelForBrowser, getLoginSlugFromSessionStorage, setLoginSlugToSessionStorage };