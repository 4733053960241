var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { colours, fontSizes, layoutSizes } from "../../constants/css";
export var Callout = styled.div.withConfig({
  displayName: "CardCallout__Callout",
  componentId: "sc-rwt9dk-0"
})(function (props) {
  var backgroundColor = props.backgroundColor,
    color = props.color,
    callType = props.callType,
    isSearch = props.isSearch,
    isLogo = props.isLogo,
    isRightTop = props.isRightTop;
  var notBackgroundColorAndCallTypeStyle = !backgroundColor && callType && "\n    background-color: ".concat((callType === 'TRIPLE' || callType === 'DOUBLE') && colours.calloutPink, ";\n    background-color: ").concat((callType === 'NEW' || callType === 'FREE' || callType === 'PROMO') && colours.calloutGrey, ";\n  ");
  var isSearchStyle = isSearch && "\n    margin-left: auto;\n    margin-top: 20px;\n    position: relative;\n    height: 20px;\n    max-width: inherit;\n    padding: 0.2rem 5px;\n  ";
  var isLogoStyle = isLogo && "\n    left: -5px;\n    top: -6px;\n    left: 67px;\n    bottom: 0;\n    top: initial;\n\n    @media only screen and (min-width: ".concat(layoutSizes.desktopWidth, ") {\n      left: -5px;\n      top: -6px;\n      bottom: initial;\n    }\n  ");
  var isRightTopStyle = isRightTop && "\n    font-size: 0.75rem;\n    font-weight: 500;\n    left: unset;\n    right: 0;\n    border-radius: 0 0 0 8px;\n    height: 1.125rem;\n    line-height: 1.125rem;\n    padding: 0 0.5rem 0 0.5rem;\n  ";
  return css(["background-color:", ";position:absolute;top:0;left:0;display:inline-block;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;vertical-align:middle;padding:0.3rem 1rem 0.3rem 0.5rem;font-size:", ";border-top-left-radius:4px;border-bottom-right-radius:10px;text-transform:uppercase;letter-spacing:1px;z-index:1;@media only screen and (min-width:", "){max-width:60%;}background-color:", ";color:", ";", " ", " ", " ", ""], colours.qantasAqua, fontSizes.xs, layoutSizes.desktopWidth, backgroundColor, color, notBackgroundColorAndCallTypeStyle, isSearchStyle, isLogoStyle, isRightTopStyle);
});
var CardCallout = function CardCallout(props) {
  var bonusRibbon = props.bonusRibbons.find(function (b) {
    return b.name === props.children;
  });
  if (bonusRibbon) {
    return __jsx(Callout, {
      isSearch: props.isSearch,
      isLogo: props.isLogo,
      isRightTop: props.isRightTop,
      backgroundColor: bonusRibbon.backgroundColor,
      color: bonusRibbon.color
    }, bonusRibbon.title);
  }
  return __jsx(Callout, {
    callType: props.callType,
    isSearch: props.isSearch,
    isLogo: props.isLogo,
    isRightTop: props.isRightTop
  }, props.children);
};
CardCallout.defaultProps = {
  callType: '',
  isSearch: false,
  isLogo: false,
  bonusRibbons: [],
  isRightTop: false
};
export default CardCallout;