import { colours, fontSizes, layoutSizes, customFonts } from "../../constants/css";
import styled from 'styled-components';
var NavWrapper = styled.footer.withConfig({
  displayName: "style__NavWrapper",
  componentId: "sc-nxmzu8-0"
})(["padding:0.5rem 0;padding:0;position:relative;"]);
export var Logo = styled.img.withConfig({
  displayName: "style__Logo",
  componentId: "sc-nxmzu8-1"
})(["max-height:50px;vertical-align:middle;display:block;margin-right:2rem;margin-bottom:1rem;@media only screen and (min-width:", "){display:inline-block;margin-bottom:0;}"], layoutSizes.desktopWidth);
var StyledFooter = styled.div.withConfig({
  displayName: "style__StyledFooter",
  componentId: "sc-nxmzu8-2"
})(["font-size:", ";margin:auto;.logo-wrapper{position:relative;}ul{list-style:none;flex-wrap:wrap;padding-left:0;}"], fontSizes.base);
export var SocialNav = styled.div.withConfig({
  displayName: "style__SocialNav",
  componentId: "sc-nxmzu8-3"
})(["max-width:650px;margin:1.5rem 0;& ul{justify-content:flex-start;margin:0 0.5rem 0 !important;order:1;@media only screen and (min-width:", "){flex:1;margin:0 8rem 0 0;justify-content:space-around;}}@media only screen and (min-width:", "){display:flex;align-items:center;.hidden-xs-down{display:block;}}"], layoutSizes.desktopWidth, layoutSizes.desktopWidth);
export var TermsList = styled.ul.withConfig({
  displayName: "style__TermsList",
  componentId: "sc-nxmzu8-4"
})(["display:flex;max-width:700px;margin-top:0;li{margin-bottom:1rem;&:first-child{margin-right:1rem;}a{color:", ";font-size:", ";text-decoration:none;}@media only screen and (min-width:", "){margin-bottom:0;margin-right:1rem;&:last-child{margin-left:auto;}}}"], colours.qantasCharcoal, fontSizes.small, layoutSizes.desktopWidth);
export var FollowTitle = styled.span.withConfig({
  displayName: "style__FollowTitle",
  componentId: "sc-nxmzu8-5"
})(["margin:0;font-size:", ";font-family:", ";@media only screen and (min-width:", "){margin:0 2rem;}"], fontSizes.small, customFonts.ciutadellaMed, layoutSizes.desktopWidth);
export var SocialWrapper = styled.div.withConfig({
  displayName: "style__SocialWrapper",
  componentId: "sc-nxmzu8-6"
})(["margin-top:1rem;& ul{display:flex;flex-flow:row wrap;max-width:500px;}& li{margin-bottom:1rem;@media only screen and (min-width:", "){width:auto;margin-bottom:0;}}& li a{color:", ";text-decoration:none;margin-right:1rem;display:block;span{margin:0 auto;}@media only screen and (min-width:", "){display:inline-block;}}"], layoutSizes.desktopWidth, colours.qantasWhite, layoutSizes.desktopWidth);
var StyledIcon = styled.a.withConfig({
  displayName: "style__StyledIcon",
  componentId: "sc-nxmzu8-7"
})(["background-color:transparent;border:none;.sprite{background-image:url('/static/social-icons.png');background-repeat:no-repeat;display:block;}.sprite-icon-facebook{width:41px;height:40px;background-position:-5px -5px;}.sprite-icon-instagram{width:42px;height:40px;background-position:-56px -5px;}.sprite-icon-linkedin{width:41px;height:40px;background-position:-5px -55px;}.sprite-icon-twitter{width:41px;height:40px;background-position:-56px -55px;}.sprite-icon-youtube{width:41px;height:40px;background-position:-108px -5px;}"]);
export { NavWrapper, StyledFooter, StyledIcon };