import _toConsumableArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var __jsx = React.createElement;
/** @format */

import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { Icon, ButtonTransparent } from "../_global";
import { queries } from "../../data/queries";
import config from "../../utils/clientConfig";
import MemberProfileMobile from "../Header/MemberProfileMobile";
import CloseIcon from "../_global/Icons/CloseIcon";
var whiteArrowDown = "/static/icons/white-arrow-down.svg";
var whiteArrowUp = "/static/icons/white-arrow-up.svg";
var openNewWindow = "/static/icons/open-new-window.svg";
import { topNavItemsType } from "../../constants/app";
import { isChannelForBrowser } from "../../utils/sessionStorageHelper";
import createUniversalNavScript from "../../utils/createUniversalNavScript";
import { MobileSidebarContainer, MenuWrapper, MenuRemainderWrapper, MenuLoginInfoSection, SideNavItemsWrapper, SideNavItem, BonusBadge } from "./style";
var useScript = function useScript() {
  useEffect(function () {
    createUniversalNavScript();
  }, []);
};
var useElement = function useElement(eleClass) {
  useEffect(function () {
    if (isChannelForBrowser()) {
      var script = document.createElement('script');
      script.type = 'qantas/widget';
      script.text = JSON.stringify(config.UNIVERSAL);
      document.querySelector(eleClass).appendChild(script);
    }
  }, [eleClass]);
};
var MobileSideNavbar = function MobileSideNavbar(_ref) {
  var isMenuOpen = _ref.isMenuOpen,
    toggleSideMenu = _ref.toggleSideMenu,
    topNavItems = _ref.topNavItems,
    authorized = _ref.authorized;
  useScript();
  useElement('.global_features_mobile');
  var promotionalCategory = topNavItems.map(function (t) {
    return {
      title: t.displayName,
      href: "/".concat(t.url),
      isPromotional: true,
      isDisplay: true
    };
  });
  var isPromotional = false;
  var _useState = useState(false),
    isMoreExpand = _useState[0],
    setIsMoreExpand = _useState[1];
  var handleMoreToggle = function handleMoreToggle() {
    setIsMoreExpand(!isMoreExpand);
  };
  var renderNavItems = function renderNavItems(navItems) {
    var isMoreItems = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return navItems.map(function (item, i) {
      return item.isDisplay && __jsx(SideNavItem, {
        key: i,
        isMoreItems: isMoreItems
      }, item.isOpenProfile ? __jsx("a", {
        onClick: toggleSideMenu,
        href: item.href,
        target: "_blank",
        rel: "noopener noreferrer"
      }, __jsx("span", null, item.title), __jsx(Icon, {
        className: "open-profile",
        iconUrl: openNewWindow,
        iconalt: "open"
      })) : __jsx(Link, {
        href: item.href
      }, __jsx("a", {
        onClick: toggleSideMenu,
        role: "link"
      }, __jsx("span", null, item.title), item.isPromotional && __jsx(BonusBadge, null, "BONUS POINTS"))));
    });
  };
  var navItems = [{
    title: 'Home',
    href: '/',
    isPromotional: isPromotional,
    isDisplay: true
  }].concat(_toConsumableArray(promotionalCategory), [{
    title: 'All Stores A - Z',
    href: '/all-stores',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'Categories',
    href: '/categories',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'Card Offers',
    href: '/card-offers',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'Favourites',
    href: '/favourites',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'Shopping history',
    href: '/shopping-history',
    isPromotional: isPromotional,
    isDisplay: true
  }]);
  var moreNavItems = [{
    title: 'Manage cards',
    href: '/manage-cards',
    isPromotional: isPromotional,
    isDisplay: authorized
  }, {
    title: 'Settings',
    href: '/settings',
    isPromotional: isPromotional,
    isDisplay: authorized
  }, {
    title: 'Your Qantas profile',
    href: 'https://www.qantas.com/au/en/frequent-flyer/my-account/profile.html',
    isPromotional: isPromotional,
    isDisplay: isChannelForBrowser() && authorized,
    isOpenProfile: true
  }, {
    title: 'Contact us',
    href: '/contact-us',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'How it works',
    href: '/how-it-works',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'FAQs',
    href: '/faqs',
    isPromotional: isPromotional,
    isDisplay: true
  }, {
    title: 'Terms and conditions',
    href: '/terms-and-conditions',
    isPromotional: isPromotional,
    isDisplay: true
  }];
  return __jsx(MobileSidebarContainer, {
    isMenuOpen: isMenuOpen
  }, __jsx(MenuWrapper, null, __jsx(MenuLoginInfoSection, null, isChannelForBrowser() && __jsx(MemberProfileMobile, {
    toggleSideMenu: toggleSideMenu
  })), __jsx(SideNavItemsWrapper, {
    isChannelForBrowser: isChannelForBrowser()
  }, renderNavItems(navItems), __jsx(SideNavItem, {
    onClick: handleMoreToggle,
    isMoreExpand: isMoreExpand
  }, __jsx("span", {
    className: "more"
  }, "More"), isMoreExpand ? __jsx(Icon, {
    iconUrl: whiteArrowUp,
    iconalt: "up"
  }) : __jsx(Icon, {
    iconUrl: whiteArrowDown,
    iconalt: "down"
  })), isMoreExpand && renderNavItems(moreNavItems, true), __jsx("div", {
    "data-widget-type": "global_features_navigation",
    className: "global_features_mobile"
  }))), __jsx(MenuRemainderWrapper, {
    onClick: toggleSideMenu
  }, __jsx(ButtonTransparent, {
    margin: "19px auto 0",
    display: "block",
    padding: "5px",
    title: "close"
  }, __jsx(CloseIcon, null))));
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var user = _ref2.user;
  return {
    authorized: user.authorized
  };
};
MobileSideNavbar.defaultProps = {
  topNavItems: []
};
export default compose(graphql(queries.getCategory, {
  props: function props(_ref3) {
    var data = _ref3.data;
    return data;
  }
}))(connect(mapStateToProps)(MobileSideNavbar));