var __jsx = React.createElement;
/** @format */

import React from 'react';
import MediaQuery from 'react-responsive';
import { compose } from 'react-apollo';
import { withRouter } from 'next/router';
import { responsiveQuery } from "../../constants/css";
import DesktopNavBar from "./DesktopNavbar";
var Navbar = function Navbar(_ref) {
  var router = _ref.router,
    showChat = _ref.showChat;
  return __jsx(React.Fragment, null, __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, __jsx(DesktopNavBar, {
    router: router,
    showChat: showChat
  })));
};
export default compose(withRouter)(Navbar);