var __jsx = React.createElement;
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonTransparent } from "./";
import CloseIcon from "./Icons/CloseIcon";
import { colours, layoutSizes, customFonts, zIndexes } from "../../constants/css";
import EventTrackingHelper from "../../utils/trackingUtilities";
import { isOfferActive, isOfferExpired } from "../../utils/checkToasters";
import Button from "./Button";
import useScrollDirection from "../../hooks/useScrollDirection";
import { useBreakpoint, SUPPORTED_SCREENS } from "../../hooks/useBreakpoint";
import storage from "../../utils/storage";
import { dismissToaster } from "../../utils/sessionStorageHelper";
import isExternalUrl from "../../utils/isExternalUrl";
var ViewPort = styled.div.withConfig({
  displayName: "Toaster__ViewPort",
  componentId: "sc-3cqjnl-0"
})(["width:100%;height:100%;overflow:hidden;position:absolute;top:0;bottom:0;pointer-events:none;"]);
var BottomBar = styled.div.withConfig({
  displayName: "Toaster__BottomBar",
  componentId: "sc-3cqjnl-1"
})(["transition:margin-bottom ease ", ";background:", ";box-shadow:0 2px 4px 0 rgba(0,0,0,0.85),0 2px 8px 0 rgba(0,0,0,0.45);position:fixed;bottom:0;width:100%;opacity:1;z-index:", ";pointer-events:visible;"], function (props) {
  return props.animation.duration;
}, colours.qantasWhite, zIndexes.toaster);
var Container = styled.div.withConfig({
  displayName: "Toaster__Container",
  componentId: "sc-3cqjnl-2"
})(["display:flex;flex-direction:column;height:100%;padding:15px;@media only screen and (min-width:", "){flex-direction:row;justify-content:center;}"], layoutSizes.toasterStyleBreakPoint);
var Header = styled.div.withConfig({
  displayName: "Toaster__Header",
  componentId: "sc-3cqjnl-3"
})(["display:flex;font-size:18px;flex-direction:column;margin-bottom:10px;@media only screen and (min-width:", "){flex-direction:", ";max-width:64%;margin-bottom:0;}"], layoutSizes.toasterStyleBreakPoint, function (props) {
  return props.hasImage ? 'row' : 'column';
});
var Title = styled.div.withConfig({
  displayName: "Toaster__Title",
  componentId: "sc-3cqjnl-4"
})(["color:", ";font-size:22px;"], colours.qantasRed);
var ToasterButton = styled(Button).withConfig({
  displayName: "Toaster__ToasterButton",
  componentId: "sc-3cqjnl-5"
})(["border-radius:4px;"]);
var HeroText = styled.div.withConfig({
  displayName: "Toaster__HeroText",
  componentId: "sc-3cqjnl-6"
})(["color:", ";font-family:", ";align-self:", ";"], colours.qantasCharcoal, customFonts.ciutadellaMed, function (props) {
  return props.hasImage ? 'center' : 'flex-start';
});
var TileImage = styled.img.withConfig({
  displayName: "Toaster__TileImage",
  componentId: "sc-3cqjnl-7"
})(["display:flex;flex-shrink:0;width:180px;height:60px;margin-right:12px;"]);
var CtaContainer = styled.div.withConfig({
  displayName: "Toaster__CtaContainer",
  componentId: "sc-3cqjnl-8"
})(["display:flex;flex-basis:20%;width:100%;@media only screen and (min-width:", "){flex-basis:0;margin-left:10px;}@media only screen and (min-width:", "){margin-left:40px;}"], layoutSizes.toasterStyleBreakPoint, layoutSizes.ipadPortraitWidth);
var Toaster = function Toaster(_ref) {
  var id = _ref.id,
    image = _ref.image,
    content = _ref.content,
    title = _ref.title,
    button = _ref.button,
    startDate = _ref.startDate,
    endDate = _ref.endDate,
    currentPath = _ref.currentPath;
  var _useState = useState(false),
    showOffer = _useState[0],
    toggleOffer = _useState[1];
  var _useScrollDirection = useScrollDirection(),
    isScrollingDown = _useScrollDirection.isScrollingDown;
  var bottomBar = useRef(null);
  var duration = !isScrollingDown ? '0.5s' : '0.3s';
  useEffect(function () {
    // Check to see if offer is in time frame window
    var canShowOffer = isOfferActive(startDate);
    var hasOfferExpired = isOfferExpired(endDate);
    var canDisplayOffer = canShowOffer && !hasOfferExpired;
    // Check has user previously dismissed this offer?
    var offers = storage.get('offers');
    if (canDisplayOffer && !offers[id]) {
      EventTrackingHelper.trackGenericAction('QCardOffersAUadvertisingTrayView', {
        offerId: id,
        uri: window.location.href
      });
      toggleOffer(true);
    }
  }, []);
  useEffect(function () {
    if (!showOffer) {
      return;
    }
    var bottomBarElem = bottomBar.current;
    var _bottomBarElem$getBou = bottomBarElem.getBoundingClientRect(),
      height = _bottomBarElem$getBou.height;
    var bottomPos = !isScrollingDown ? '0' : "-".concat(height, "px");
    bottomBar.current.style.marginBottom = bottomPos;
  }, [isScrollingDown]);
  var _useBreakpoint = useBreakpoint(),
    breakpoint = _useBreakpoint.breakpoint;
  var canShowImage = breakpoint !== SUPPORTED_SCREENS.MOBILE && image;
  var canShowTitle = !canShowImage && title;
  var closeOffer = function closeOffer(path) {
    dismissToaster(path);
    toggleOffer(false);
    EventTrackingHelper.trackGenericAction('QCardOffersAUadvertisingTrayClosed', {
      offerId: id,
      uri: window.location.href
    });
  };
  if (!showOffer) {
    return null;
  }
  var redirectUrl = function redirectUrl() {
    var isExternal = isExternalUrl(button.url);
    EventTrackingHelper.trackGenericAction('QCardOffersAUadvertisingTrayClick', {
      offerId: id,
      uri: window.location.href,
      redirectURL: button.url
    });
    if (isExternal) {
      window.open(button.url, '_blank');
    } else {
      window.location = button.url;
    }
  };
  return __jsx(ViewPort, null, __jsx(BottomBar, {
    ref: bottomBar,
    isScrollingDown: isScrollingDown,
    animation: {
      duration: duration
    }
  }, __jsx(ButtonTransparent, {
    onClick: function onClick() {
      return closeOffer(currentPath);
    },
    position: "absolute",
    top: "15px",
    right: "15px"
  }, __jsx(CloseIcon, {
    width: "16px",
    height: "16px",
    color: "#4a4a4a"
  })), __jsx(Container, null, __jsx(Header, {
    hasImage: canShowImage
  }, canShowImage && __jsx(TileImage, image), canShowTitle && __jsx(Title, null, title), __jsx(HeroText, {
    hasImage: canShowImage
  }, content)), __jsx(CtaContainer, null, __jsx(ToasterButton, {
    block: true,
    outline: true,
    rounded: true,
    onClick: redirectUrl,
    asLink: button.url
  }, button.label)))));
};
Toaster.defaultProps = {
  title: undefined,
  image: null
};
export default Toaster;