function linkHost(url) {
  if (/^https?:\/\//.test(url)) {
    // Absolute URL.
    var parser = document.createElement('a');
    parser.href = url;
    return parser.hostname;
  }
  return window.location.hostname;
}
function isExternalUrl(url) {
  var host = window.location.hostname;
  return host !== linkHost(url);
}
export default isExternalUrl;