var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fontSizes, headingSizes, colours, responsiveQuery } from "../../constants/css";
import { Icon, Button } from "./";
var ThreeStepsContainer = styled.div.withConfig({
  displayName: "ThreeSteps__ThreeStepsContainer",
  componentId: "sc-4onlf2-0"
})(["@media only screen and ", "{display:flex;justify-content:center;text-align:center;}"], responsiveQuery.tabletAndDesktop);
var StepContainer = styled.div.withConfig({
  displayName: "ThreeSteps__StepContainer",
  componentId: "sc-4onlf2-1"
})(["text-align:center;max-width:350px;margin:0 auto 20px;h3{font-size:", ";font-weight:normal;margin:0 0 12px;}p{color:", ";line-height:1.5;font-size:", ";}@media only screen and ", "{max-width:33.3333%;padding:0 22px;margin:0;}"], headingSizes.h3, colours.qantasGrey40, fontSizes.large, responsiveQuery.tabletAndDesktop);
var ImageContainer = styled.div.withConfig({
  displayName: "ThreeSteps__ImageContainer",
  componentId: "sc-4onlf2-2"
})(["height:140px;display:flex;justify-content:center;align-items:center;margin-bottom:20px;i{display:none;}@media only screen and ", "{position:relative;margin-bottom:50px;i{position:absolute;right:-22px;top:50%;display:block;}}"], responsiveQuery.tabletAndDesktop);
var Footer = styled.div.withConfig({
  displayName: "ThreeSteps__Footer",
  componentId: "sc-4onlf2-3"
})(["button{display:block;padding:1rem 1.75rem;margin-left:auto;margin-right:auto;}@media only screen and ", "{button{display:inline-block;}}"], responsiveQuery.tabletAndDesktop);
function ThreeSteps(_ref) {
  var steps = _ref.steps,
    button = _ref.button;
  return __jsx(React.Fragment, null, __jsx(ThreeStepsContainer, null, steps.map(function (step, index) {
    return __jsx(StepContainer, null, __jsx(ImageContainer, null, step.image, index + 1 !== steps.length && __jsx(Icon, {
      iconType: "chevronRight",
      size: "xs"
    })), __jsx("div", null, __jsx("h3", null, index + 1, ". ", step.title), __jsx("p", null, step.description)));
  })), __jsx(Footer, null, __jsx(Button, {
    pageLink: button.url,
    primary: true
  }, button.label)));
}
export default ThreeSteps;