import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["iconUrl", "iconalt"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { customFonts, fontSizes } from "../../constants/css";
var iconTypes = {
  profile: 'F116',
  caretUp: 'F104',
  caretDown: 'F101',
  chevronDown: 'F106',
  chevronUp: 'F107',
  chevronRight: 'F103',
  closeCross: 'F109',
  tick: 'F118',
  search: 'F117',
  arrowRight: 'F115',
  arrowLeft: 'F115',
  arrowTipRight: 'F104',
  upload: 'F0ee'
};
var IconTemplate = styled.i.withConfig({
  displayName: "Icon__IconTemplate",
  componentId: "sc-1cyg4pc-0"
})(["display:inline-block;font-size:", ";&:before{content:", ";display:inline-block;font-size:inherit;font-family:", ";font-weight:400;font-style:normal;line-height:1;text-decoration:inherit;text-transform:none;text-rendering:auto;", " ", "}"], function (props) {
  return props.size;
}, function (props) {
  return "\"\\".concat(iconTypes[props.iconType], "\"");
}, customFonts.qsIcons, function (props) {
  return props.iconType === 'arrowLeft' && "\n      transform: scaleX(-1);\n    ";
}, function (props) {
  return props.iconType === 'arrowTipRight' && "\n      transform: rotate(90deg);\n    ";
});
var Icon = function Icon(props) {
  var iconUrl = props.iconUrl,
    iconalt = props.iconalt,
    otherProps = _objectWithoutProperties(props, _excluded);
  return __jsx(React.Fragment, null, props.iconType && __jsx(IconTemplate, _extends({}, props, {
    iconType: props.iconType,
    size: fontSizes[props.size]
  })), props.iconUrl && __jsx("img", _extends({}, otherProps, {
    src: iconUrl,
    alt: iconalt
  })));
};
Icon.defaultProps = {
  iconUrl: null,
  iconalt: null,
  iconType: null
};
Icon.defaultProps = {
  size: 'base'
};
export default Icon;