import Cookies from 'universal-cookie';
import { getOAuthUserInfo } from "./sessionStorageHelper";
import { LSL_SESSION_TOKEN_NAME, LSL_TOKEN_NAME } from "../constants/app";
export var COOKIE_NAMES = {
  OAUTH_USER_INFO: 'oauthUserInfo'
};
var cookies = new Cookies();
var getLslAccessTokenFromCookie = function getLslAccessTokenFromCookie() {
  return cookies.get(LSL_TOKEN_NAME);
};
var getOAuthUserInfoFromCookie = function getOAuthUserInfoFromCookie() {
  var cookieName = COOKIE_NAMES.OAUTH_USER_INFO;
  var oauthUserInfo = cookies.get(cookieName);
  if (oauthUserInfo) {
    return oauthUserInfo;
  }
  return null;
};
var setOAuthUserInfo = function setOAuthUserInfo(userInfo) {
  var cookieName = COOKIE_NAMES.OAUTH_USER_INFO;
  var lastName = userInfo.family_name,
    firstName = userInfo.given_name,
    points = userInfo.qff_points,
    ffn = userInfo.user_id;
  var userInfoObject = {
    lastName: lastName,
    firstName: firstName,
    points: points,
    ffn: ffn
  };
  cookies.set(cookieName, userInfoObject, {
    path: '/'
  });
  // todo: EXPIRY?
};
var removeOAuthUserInfo = function removeOAuthUserInfo() {
  var cookieName = COOKIE_NAMES.OAUTH_USER_INFO;
  cookies.remove(cookieName, {
    path: '/'
  });
};
var getLslSessionToken = function getLslSessionToken() {
  return cookies.get(LSL_SESSION_TOKEN_NAME) || null;
};
var getMemberFirstName = function getMemberFirstName() {
  var userInfo = getOAuthUserInfo();
  if (userInfo !== null && userInfo !== void 0 && userInfo.firstName) {
    return userInfo.firstName;
  }
  return "";
};
export { getLslAccessTokenFromCookie, getLslSessionToken, getMemberFirstName,
// login cookie
getOAuthUserInfoFromCookie, setOAuthUserInfo, removeOAuthUserInfo };