import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Cookies from 'universal-cookie';
import { FEATURE_TOGGLE, getFeatureFlag } from "../../../utils/getLDFlag";
import { GEO_NZ_IP_COOKIE_NAME } from "../../../constants/app";
import GeoNZIPModal from "./GeoNZIPModal";
var confirmRedirectUri = "https://www.qantas.com/nz/en/frequent-flyer/partners/everyday-rewards.html";
var getGeoNZIPCookieValue = function getGeoNZIPCookieValue() {
  return new Cookies().get(GEO_NZ_IP_COOKIE_NAME);
};
var setGeoNZIPCookieValue = function setGeoNZIPCookieValue() {
  return new Cookies().set(GEO_NZ_IP_COOKIE_NAME, 'hide', {
    path: '/'
  });
};
function GeoNZIPModalContainer(_ref) {
  var _ref$anonymousLDFlags = _ref.anonymousLDFlags,
    anonymousLDFlags = _ref$anonymousLDFlags === void 0 ? {} : _ref$anonymousLDFlags,
    flags = _ref.flags,
    getGeoIp = _ref.getGeoIp;
  var _useState = useState(false),
    showModal = _useState[0],
    setShowModal = _useState[1];
  var geoNZIPEnabled = getFeatureFlag({
    key: FEATURE_TOGGLE.EDR_NZ_IP_PROMPT_ENABLED,
    flags: flags,
    anonymousLDFlags: anonymousLDFlags
  });
  var dismissGeoNZIPPrompt = function dismissGeoNZIPPrompt() {
    setShowModal(false);
    setGeoNZIPCookieValue();
  };
  useEffect(function () {
    if (geoNZIPEnabled && getGeoIp !== null && getGeoIp !== void 0 && getGeoIp.isInNZ && getGeoNZIPCookieValue() !== 'hide') {
      setShowModal(true);
    }
  }, [getGeoIp]);
  if (!showModal) {
    return null;
  }
  return __jsx(GeoNZIPModal, {
    show: true,
    onConfirm: function onConfirm() {
      dismissGeoNZIPPrompt();
      window.location.href = confirmRedirectUri;
    },
    onReject: dismissGeoNZIPPrompt
  });
}
var mapState = function mapState(_ref2) {
  var flags = _ref2.flags;
  return {
    flags: flags
  };
};
var geoNZIPQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getGeoIpQuery(\n    $ipAddress: String!\n  ) {\n    getGeoIp(\n      ipAddress: $ipAddress\n    ) {\n      isInNZ\n    }\n  }\n"])));
export default compose(connect(mapState), graphql(geoNZIPQuery, {
  skip: function skip() {
    return getGeoNZIPCookieValue() === 'hide';
  },
  props: function props(_ref3) {
    var getGeoIp = _ref3.data.getGeoIp;
    return {
      getGeoIp: getGeoIp
    };
  },
  options: function options(ownProps) {
    return {
      variables: {
        ipAddress: ownProps.ipAddress
      },
      ssr: false
    };
  }
}))(GeoNZIPModalContainer);