import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["query"],
  _excluded2 = ["query"];
var _DEFAULT_BREAKPOINTS;
import { useEffect, useMemo, useState } from 'react';
var SUPPORTED_SCREENS = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP'
};
var DEFAULT_BREAKPOINTS = (_DEFAULT_BREAKPOINTS = {}, _defineProperty(_DEFAULT_BREAKPOINTS, SUPPORTED_SCREENS.MOBILE, 0), _defineProperty(_DEFAULT_BREAKPOINTS, SUPPORTED_SCREENS.TABLET, 768), _defineProperty(_DEFAULT_BREAKPOINTS, SUPPORTED_SCREENS.DESKTOP, 970), _DEFAULT_BREAKPOINTS);
var createMediaQueries = function createMediaQueries(breakpoints) {
  var sortedBreakpoints = Object.keys(breakpoints).sort(function (a, b) {
    return breakpoints[b] - breakpoints[a];
  });
  return sortedBreakpoints.map(function (breakpoint, index) {
    var query = '';
    var minWidth = breakpoints[breakpoint];
    var nextBreakpoint = sortedBreakpoints[index - 1];
    var maxWidth = nextBreakpoint ? breakpoints[nextBreakpoint] : undefined;
    if (minWidth >= 0) {
      query = "(min-width: ".concat(minWidth, "px)");
    }
    if (typeof maxWidth !== 'undefined') {
      if (query) {
        query += ' and ';
      }
      query += "(max-width: ".concat(maxWidth - 1, "px)");
    }
    return {
      breakpoint: breakpoint,
      maxWidth: maxWidth,
      minWidth: minWidth,
      query: query
    };
  });
};
function useBreakpoint() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_BREAKPOINTS;
  var defaultBreakpoint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : SUPPORTED_SCREENS.DESKTOP;
  var mediaQueries = useMemo(function () {
    return createMediaQueries(config);
  }, [config]);
  var _useState = useState(function () {
      if (!defaultBreakpoint) {
        return undefined;
      }
      var _mediaQueries$find = mediaQueries.find(function (_ref) {
          var br = _ref.breakpoint;
          return br === defaultBreakpoint;
        }),
        query = _mediaQueries$find.query,
        breakpoint = _objectWithoutProperties(_mediaQueries$find, _excluded);
      return breakpoint;
    }),
    currentBreakpoint = _useState[0],
    setCurrentBreakpoint = _useState[1];
  var updateBreakpoint = function updateBreakpoint(_ref2, breakpoint) {
    var matches = _ref2.matches;
    if (matches) {
      setCurrentBreakpoint(breakpoint);
    }
  };
  useEffect(function () {
    mediaQueries.forEach(function (_ref3) {
      var query = _ref3.query,
        breakpoint = _objectWithoutProperties(_ref3, _excluded2);
      var mediaQuery = window.matchMedia(query);
      updateBreakpoint(mediaQuery, breakpoint);
      var handleChange = function handleChange() {
        return updateBreakpoint(mediaQuery, breakpoint);
      };
      mediaQuery.addListener(handleChange);
      return function () {
        return mediaQuery.removeListener(handleChange);
      };
    });
  }, [config]);
  return currentBreakpoint;
}
export { DEFAULT_BREAKPOINTS, SUPPORTED_SCREENS, useBreakpoint };