var logger = function logger() {
  var appName = 'sho-website';
  var info = function info(message) {
    console.log(JSON.stringify({
      app: appName,
      applicationName: appName,
      level: 'INFO',
      log: message,
      timestamp: new Date().toISOString()
    }));
  };
  var error = function error(message) {
    console.log(JSON.stringify({
      app: appName,
      applicationName: appName,
      level: 'ERROR',
      log: message,
      timestamp: new Date().toISOString()
    }));
  };
  return {
    info: info,
    error: error
  };
};
module.exports = logger();