/** @format */

import axios from 'axios';
var initialState = {};
var actionTypes = {
  GET_FLAGS_SUCCESS: 'GET_FLAGS_SUCCESS'
};
export var getFlagsSuccess = function getFlagsSuccess(flags) {
  return {
    type: actionTypes.GET_FLAGS_SUCCESS,
    flags: flags
  };
};
export var getFeatureFlags = function getFeatureFlags() {
  return function (dispatch, getState) {
    var _getState = getState(),
      ffn = _getState.user.ffn;
    var headers = ffn ? {
      ffn: ffn,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    } : null;
    return axios.get('/flags', {
      headers: headers
    }).then(function (response) {
      var flags = response.data;
      dispatch({
        type: actionTypes.GET_FLAGS_SUCCESS,
        flags: flags
      });
      return flags;
    });
  };
};
export var flagReducer = function flagReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === actionTypes.GET_FLAGS_SUCCESS) {
    return action.flags;
  }
  return state;
};