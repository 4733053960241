import { colours, fontSizes, layoutSizes } from "../../constants/css";
import styled from 'styled-components';
import { Icon } from "../_global";
var NavWrapper = styled.footer.withConfig({
  displayName: "style__NavWrapper",
  componentId: "sc-1dygtua-0"
})(["background-color:", ";border-top:4px solid ", ";padding:0;position:relative;"], colours.qantasCharcoal, colours.qantasRed);
export var LogoWrapper = styled.div.withConfig({
  displayName: "style__LogoWrapper",
  componentId: "sc-1dygtua-1"
})(["position:relative;img{margin:0.8rem 0 0 0;}"]);
var StyledFooter = styled.div.withConfig({
  displayName: "style__StyledFooter",
  componentId: "sc-1dygtua-2"
})(["font-size:", ";padding:1rem 0;margin:auto;& ul{list-style:none;padding-left:0;}"], fontSizes.base);
export var DownArrow = styled(Icon).withConfig({
  displayName: "style__DownArrow",
  componentId: "sc-1dygtua-3"
})([""]);
export var AnchorTop = styled.button.withConfig({
  displayName: "style__AnchorTop",
  componentId: "sc-1dygtua-4"
})(["cursor:pointer;background-color:", ";text-align:center;align-items:center;justify-content:center;width:50px;height:50px;display:flex;position:absolute;top:-15px;right:12px;"], colours.qantasWhite);
export var SubLinks = styled.div.withConfig({
  displayName: "style__SubLinks",
  componentId: "sc-1dygtua-5"
})(["padding-bottom:5px;> div{padding-left:0;}& ul{display:flex;flex-flow:row wrap;margin-top:0;margin-bottom:0;}li{display:inherit;padding-top:5px;}& li a{color:", ";text-decoration:none;padding:0.75rem 1rem;font-size:", ";&:hover{text-decoration:underline;}@media only screen and (min-width:", "){margin-right:0.5rem;}}"], colours.qantasWhite, fontSizes.small, layoutSizes.desktopWidth);
export { NavWrapper, StyledFooter };