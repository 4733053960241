var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import config from "../../utils/clientConfig";
import { StyledHead, HeadContainer, Logo } from "./style";
import MemberProfile from "./MemberProfile";
import FavouriteNav from "./FavouriteNav";
var DesktopHeader = function DesktopHeader(_ref) {
  var showGlobalNav = _ref.showGlobalNav;
  return __jsx(StyledHead, null, __jsx("div", {
    "data-widget-type": "global_features_navigation",
    className: "".concat(showGlobalNav ? '' : 'sr-only', " uni-header")
  }, __jsx("script", {
    defer: true,
    type: "qantas/widget",
    dangerouslySetInnerHTML: {
      __html: JSON.stringify(config.UNIVERSAL)
    }
  })), __jsx(HeadContainer, null, __jsx(Link, {
    href: "/"
  }, __jsx("a", {
    title: "link to home"
  }, __jsx(Logo, null))), __jsx(FavouriteNav, null), __jsx(MemberProfile, null)));
};
DesktopHeader.defaultProps = {
  showGlobalNav: true
};
export default DesktopHeader;