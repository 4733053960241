import { combineReducers } from 'redux';
import { userReducer } from "./user";
import { shopReducer } from "./shop";
import { flagReducer } from "./flag";
import { chatBoxReducer } from "./ChatBox";
import { markAsReducer } from "./markAs";
import { partnerReducer } from "./partnerLinking";
import { offersReducer } from "./offers";
var appReducer = combineReducers({
  user: userReducer,
  shop: shopReducer,
  flags: flagReducer,
  chatBox: chatBoxReducer,
  markAs: markAsReducer,
  partner: partnerReducer,
  offers: offersReducer
});
var rootReducer = function rootReducer(state, action) {
  return appReducer(state, action);
};
export default rootReducer;