import styled from 'styled-components';
import { colours } from "../../../constants/css";
export var Section = styled.section.withConfig({
  displayName: "style__Section",
  componentId: "sc-wurodn-0"
})(["display:flex;border:1px solid #262626;color:", ";"], colours.qantasWhite);
export var Button = styled.button.withConfig({
  displayName: "style__Button",
  componentId: "sc-wurodn-1"
})(["margin:12px;border:2px solid ", ";border-radius:4px;background-color:transparent;padding:12px 0;flex:1;color:", ";"], colours.qantasWhite, colours.qantasWhite);
export var ProfileLoginWrapper = styled.div.withConfig({
  displayName: "style__ProfileLoginWrapper",
  componentId: "sc-wurodn-2"
})(["margin:28px 17px 14px 24px;width:100%;.name{font-size:28px;margin-bottom:6px;}"]);
export var MessageWrapper = styled.div.withConfig({
  displayName: "style__MessageWrapper",
  componentId: "sc-wurodn-3"
})(["display:flex;justify-content:", ";a{color:", ";text-decoration:underline;}"], function (props) {
  return props.justifyContent;
}, colours.qantasWhite);