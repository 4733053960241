var __jsx = React.createElement;
import React from 'react';
import Head from 'next/head';
import config from "../../utils/clientConfig";
var openLogo = "/static/logos/opengraph.png";
var Layout = function Layout(props) {
  return __jsx("div", null, __jsx(Head, null, __jsx("title", null, props.title), __jsx("link", {
    rel: "canonical",
    href: "".concat(config.WEB_HOST).concat(props.slug),
    key: "canonical"
  }), __jsx("meta", {
    property: "og:title",
    content: "".concat(props.title, " | Qantas Shopping")
  }), __jsx("meta", {
    property: "og:url",
    content: "".concat(config.WEB_HOST).concat(props.slug)
  }), __jsx("meta", {
    property: "og:image",
    content: props.logo || openLogo
  }), __jsx("meta", {
    name: "description",
    content: props.description
  }), __jsx("meta", {
    property: "og:description",
    content: props.description
  }), props.noCrawl && __jsx("meta", {
    name: "robots",
    content: "noindex"
  })), props.children);
};
Layout.defaultProps = {
  noCrawl: false,
  logo: ''
};
export default Layout;