import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @format */

import { ApolloClient } from 'apollo-client';
import { from, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-unfetch';
import Router from 'next/router';
import { getLslAccessTokenFromCookie, getMemberFirstName } from "../utils/loginHelper";
import { getShoSessionTokens, createShoSession, refreshShoSession, removeShoSessionTokens } from "../utils/sessionHelper";
import config from "../utils/clientConfig";

/*
  TODO:
  Fetch this file dynamically at the build step when we have
  more types so we don't need to maintain the list manually.
*/
import introspectionQueryResultData from "./fragmentTypes.json";
var apolloClient = null;
if (!process.browser) {
  global.fetch = fetch;
}
var httpLink = new HttpLink({
  uri: config.QUERY_API_BASE
});
var fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData
});
var authLink = setContext(function (_, _ref) {
  var headers = _ref.headers;
  var _getShoSessionTokens = getShoSessionTokens(),
    shoToken = _getShoSessionTokens.accessToken;
  var lslToken = getLslAccessTokenFromCookie();
  var token = shoToken || lslToken;
  if (token) {
    return {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        authorization: "Bearer ".concat(token)
      })
    };
  }
  return headers;
});
var errorLink = onError(function (_ref2) {
  var graphQLErrors = _ref2.graphQLErrors,
    operation = _ref2.operation,
    forward = _ref2.forward;
  if (graphQLErrors) {
    console.log('graphQLErrors[0].message', graphQLErrors[0].message);
    // User access token has expired
    if (graphQLErrors[0].message === 'Access token expired' || graphQLErrors[0].message === 'MemberId is missing') {
      // Get new token
      return new Observable(function (observer) {
        refreshShoSession().then(function (SHOToken) {
          operation.setContext(function (_ref3) {
            var _ref3$headers = _ref3.headers,
              headers = _ref3$headers === void 0 ? {} : _ref3$headers;
            return {
              headers: _objectSpread(_objectSpread({}, headers), {}, {
                // Switch out old access token for new one
                authorization: "Bearer ".concat(SHOToken)
              })
            };
          });
        }).then(function () {
          var subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        }).catch(function (error) {
          // No refresh or client token available
          observer.error(error);
        });
      });
    }
    if (graphQLErrors[0].message === 'Authentication uplift required') {
      var lslToken = getLslAccessTokenFromCookie();
      var firstName = getMemberFirstName();
      if (!lslToken || !firstName) {
        return false;
      }

      // Get new token
      return new Observable(function (observer) {
        createShoSession(lslToken, firstName).then(function (_ref4) {
          var accessToken = _ref4.accessToken;
          operation.setContext(function (_ref5) {
            var _ref5$headers = _ref5.headers,
              headers = _ref5$headers === void 0 ? {} : _ref5$headers;
            return {
              headers: _objectSpread(_objectSpread({}, headers), {}, {
                // Switch out old access token for new one
                authorization: "Bearer ".concat(accessToken)
              })
            };
          });
        }).then(function () {
          var subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        }).catch(function (error) {
          // No refresh or client token available
          observer.error(error);
        });
      });
    }
    if (graphQLErrors[0].message === 'Refresh token expired') {
      // Remove session tokens and reload current page to prompt login
      removeShoSessionTokens();
      Router.push(window.location.pathname);
    }
  }
  return false;
});
var create = function create(initialState) {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    link: process.browser ? from([authLink, errorLink, httpLink]) : httpLink,
    cache: new InMemoryCache({
      addTypename: false,
      fragmentMatcher: fragmentMatcher
    }).restore(initialState || {})
  });
};
var initApollo = function initApollo(initialState) {
  if (!process.browser) {
    return create(initialState);
  }
  if (!apolloClient) {
    apolloClient = create(initialState);
  }
  return apolloClient;
};
export default initApollo;