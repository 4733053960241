var __jsx = React.createElement;
import React from 'react';
import { connect } from 'react-redux';
var shoppingBagIcon = "/static/icons/shopping_bag.svg";
import styled from 'styled-components';
import { Modal, Button } from "../../_global";
import { closeNewUserModal, resetMemberLastInteractedTimestamp } from "../../../reducers/user";
import Router from 'next/router';
import MediaQuery from 'react-responsive';
import { colours, customFonts, fontSizes, responsiveQuery } from "../../../constants/css";
import { NEW_USER_OPT_OUT_COOKIE_NAME } from "../../../constants/app";
import Cookies from 'universal-cookie';
import { getDaysFromTodayDate } from "../../../utils/dateUtils";
var cookies = new Cookies();
var Container = styled.div.withConfig({
  displayName: "NewUserModal__Container",
  componentId: "sc-159itu-0"
})(["margin:3rem 0 2rem;padding:0 2rem;text-align:center;"]);
var ContainerTextHeader = styled.div.withConfig({
  displayName: "NewUserModal__ContainerTextHeader",
  componentId: "sc-159itu-1"
})(["font-size:26px;line-height:36px;margin-bottom:1.5rem;"]);
var ContainerTextBody = styled.div.withConfig({
  displayName: "NewUserModal__ContainerTextBody",
  componentId: "sc-159itu-2"
})(["color:", ";font-size:", ";line-height:27px;margin-bottom:1.5rem;"], colours.qantasGrey40, fontSizes.large);
var CallToAction = styled(Button).withConfig({
  displayName: "NewUserModal__CallToAction",
  componentId: "sc-159itu-3"
})(["margin:1rem auto 0;max-width:250px;display:block;font-size:", ";margin-bottom:1rem;", ""], fontSizes.base, function (props) {
  return props.link && "\n    margin: 0 auto;\n    text-transform: none;\n    color: ".concat(colours.qantasRed, ";\n    font-size: ").concat(fontSizes.large, ";\n    line-height: 27px;\n    font-family: ").concat(customFonts.ciutadellaReg, "\n  ");
});
var HeaderImg = styled.img.withConfig({
  displayName: "NewUserModal__HeaderImg",
  componentId: "sc-159itu-4"
})(["margin-bottom:1rem;"]);
var routeToPage = function routeToPage(pageLink, asLink) {
  return (asLink && Router.push(pageLink, asLink) || Router.push(pageLink)).then(function () {
    return window.scrollTo(0, 0);
  });
};
var setOptOutCookie = function setOptOutCookie() {
  return cookies.set(NEW_USER_OPT_OUT_COOKIE_NAME, true, {
    expires: getDaysFromTodayDate(1),
    path: '/'
  });
};
var NewUserModal = function NewUserModal(_ref) {
  var showNewUserModal = _ref.showNewUserModal,
    dismissModal = _ref.closeNewUserModal,
    resetLastInteractionTime = _ref.resetMemberLastInteractedTimestamp;
  var handleOptIn = function handleOptIn() {
    setOptOutCookie();
    dismissModal();
    resetLastInteractionTime();
    routeToPage('/how-it-works', '/how-it-works');
  };
  var handleOptOut = function handleOptOut() {
    setOptOutCookie();
    dismissModal();
    resetLastInteractionTime();
  };
  return __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, __jsx(Modal, {
    show: showNewUserModal,
    onClose: handleOptOut,
    width: 440
  }, __jsx(Container, null, __jsx(HeaderImg, {
    src: shoppingBagIcon
  }), __jsx(ContainerTextHeader, null, "Hello, it looks like you're new here"), __jsx(ContainerTextBody, null, "You've just discovered one of the easiest ways to earn Qantas Points! Learn more about Qantas Shopping with our easy step-by-step guide."), __jsx(CallToAction, {
    primary: true,
    block: true,
    onPress: handleOptIn
  }, "GET STARTED"), __jsx(CallToAction, {
    link: true,
    onPress: dismissModal
  }, "Remind me later"))));
};
NewUserModal.defaultProps = {
  showNewUserModal: false
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var showNewUserModal = _ref2.user.showNewUserModal;
  return {
    showNewUserModal: showNewUserModal
  };
};
var mapDispatchToProps = {
  closeNewUserModal: closeNewUserModal,
  resetMemberLastInteractedTimestamp: resetMemberLastInteractedTimestamp
}; // bind action creators implicitly

export default connect(mapStateToProps, mapDispatchToProps)(NewUserModal);