import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { removeShoSessionTokens } from "../../utils/sessionHelper";
import { syncShoSession, openLoginModal, closeLoginModal } from "../../reducers/user";
import { memberProfilePropType, memberProfileDefaultProps } from "../../constants/app";
function withMemberProfile(WrappedComponent) {
  var Container = function Container(props) {
    var removeAndSyncShoSession = function removeAndSyncShoSession() {
      removeShoSessionTokens();
      var authenticated = props.authenticated,
        authorized = props.authorized;
      if (authorized && !authenticated) {
        props.syncShoSession();
      }
    };
    return __jsx(WrappedComponent, _extends({
      removeAndSyncShoSession: removeAndSyncShoSession
    }, props));
  };
  var mapStateToProps = function mapStateToProps(_ref) {
    var user = _ref.user,
      flags = _ref.flags;
    return {
      authorized: user.authorized,
      firstName: user.firstName,
      lastName: user.lastName,
      ffn: user.ffn,
      points: user.points,
      authenticated: user.authenticated,
      flags: flags,
      showOptIn: user.showOptIn,
      showNewUserModal: user.showNewUserModal
    };
  };
  var mapDispatchToProps = {
    openLoginModal: openLoginModal,
    closeLoginModal: closeLoginModal,
    syncShoSession: syncShoSession
  };
  Container.defaultProps = memberProfileDefaultProps;
  return connect(mapStateToProps, mapDispatchToProps)(Container);
}
export default withMemberProfile;