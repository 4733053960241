import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FEATURE_TOGGLE, getFeatureFlag } from "../utils/getLDFlag";
export var LSL_SESSION_TOKEN_NAME = 'qlff_wl_sess';
export var LSL_TOKEN_NAME = 'qs_lsl_token';
export var SHO_SESSION_ACCESS_TOKEN_NAME = 'qs_access_token';
export var SHO_SESSION_REFRESH_TOKEN_NAME = 'qs_refresh_token';
export var SHO_FAVOURITES_CALLOUT_COOKIE_NAME = 'qf_favourites_callout';
export var SHO_BONUS_FAVOURITES_CALLOUT_COOKIE_NAME = 'qf_bonus_favourites_callout';
export var SHO_BONUS_BANNER_CALLOUT_COOKIE_NAME = 'qf_bonus_banner_callout';
export var RED_PLANET_COOKIE_NAME = 'QF_VALUE';
export var MARKETING_EMAIL_COOKIE_NAME = 'QF_MARKETING_OPTION';
export var NEW_USER_OPT_OUT_COOKIE_NAME = 'QF_NEW_USER_OPT_OUT';
export var SITE_DATA_COOKIE_NAME = 'QF_MARKETING_SITE_DATA';
export var TOKEN_DECODE_FAILED_MESSAGE = 'Failed to get memberId, check if memberId is encrypted and valid';
export var TOKEN_EXPIRED_MESSAGE = 'Failed to get memberId, encrypted FF has expired.';
export var GEO_NZ_IP_COOKIE_NAME = 'QF_GEO_NZ_IP';
export var CLIENT_IDLE_TIMEOUT = 900000;
export var OFFER_TYPES = {
  MALL: 'MALL',
  MCO: 'MCO',
  NURSERY: 'NURSERY',
  DIRECT: 'DIRECT',
  CATEGORIES: 'CATEGORIES'
};
export var REDEMPTION_TYPES = {
  ONLINE: 'ONLINE',
  INSTORE: 'INSTORE',
  BOTH: 'BOTH'
};
export var DIRECT_TYPES = {
  EARN: 'Earn',
  EARN_BONUS: 'Earn Bonus',
  USE: 'Use',
  USE_BONUS: 'Use Bonus'
};
export var pointsCalculator = function pointsCalculator(pointsEarn, prevPoints) {
  if (pointsEarn / prevPoints === 2) {
    return 'DOUBLE POINTS';
  } else if (pointsEarn / prevPoints === 3) {
    return 'TRIPLE POINTS';
  }
  return 'BONUS POINTS';
};
export var isOfferValid = function isOfferValid(startDate, endDate) {
  // UTC to AEST offset
  var today = moment.tz('Australia/Sydney').format('YYYY-MM-DD');
  return !!(moment(startDate).isSameOrBefore(today, 'day') && (moment(endDate).isSameOrAfter(today, 'day') || endDate === null));
};
export var merchantCardCallout = function merchantCardCallout(merchant) {
  if (merchant.is_new) {
    return 'NEW STORE';
  }
  if (merchant.has_free_ship) {
    return 'FREE SHIPPING';
  }
  if (merchant.has_coupon_code) {
    return 'PROMO CODE';
  }
  return '';
};
export var OFFER_STATUS = {
  ACTIVATED: 'ACTIVATED',
  ERROR: 'ERROR',
  ACTIVATING: 'ACTIVATING'
};
export var MISSING_POINTS = 'Missing Points';
export var MARKETING_EMAIL_CODE = 'NTRP';
export var INTERSTITIAL_REDIRECT_TIME = 3000;
export var ACCESS_LEVEL = {
  LOW: 'authorized',
  HIGH: 'authenticated'
};
export var PLUGIN_STORES = {
  chrome: 'https://chrome.google.com/webstore/detail/qantas-shopping-points-pr/jchomknmfdaeojlimglgebnjlijedgnk',
  firefox: 'https://addons.mozilla.org/en-US/firefox/addon/qantas-shopping-point-prompter/',
  safari: 'https://apps.apple.com/au/app/qantas-shopping-pointsprompter/id1468230799'
};
export var HISTORY_TYPE = {
  SHOPPING: 'SHOPPING',
  CLICK: 'CLICK'
};
export var DATE_FILTER_OPTIONS = [{
  name: 'Last 3 months',
  value: 'Last3months'
}, {
  name: 'Last 6 months',
  value: 'Last6months'
}, {
  name: 'Last 12 months',
  value: 'Last12months'
}];
export var CLICK_HISTORY_SORTING_ITEMS = {
  STORE: 'STORE',
  DATE: 'DATE'
};

// The name (except for default channel SHO_WEB) should be consistent
// with the query parameter (?channel=) that will be passed in
export var CHANNEL = {
  SHO_WEB: 'sho',
  QAPP_IOS: 'qai',
  QAPP_ANDROID: 'qaa',
  QMONEY_IOS: 'qmi',
  QMONEY_ANDROID: 'qma',
  QWELLBEING_IOS: 'qwi',
  QWELLBEING_ANDROID: 'qwa',
  ANZ: 'anz'
};
export var MERCHANT_PAGE_ROUTE = '/merchant';
export var SHOPPING_HISTORY_PAGE_ROUTE = '/shopping-history';
export var SESSION_STORAGE_KEY_NAMES = {
  MOBILE_APP_LANDING_PAGE: 'mobile_landing_page',
  MOBILE_APP_CHANNEL: 'mobile_app_channel',
  LOGIN_SLUG_NAME: 'login_slug'
};
export var topNavItemsType = PropTypes.arrayOf(PropTypes.shape({
  displayName: PropTypes.string,
  url: PropTypes.string
}));
export var categoriesType = PropTypes.arrayOf(PropTypes.shape({
  displayOrder: PropTypes.number,
  displayName: PropTypes.string,
  categoryId: PropTypes.string,
  subCategories: PropTypes.arrayOf(PropTypes.shape({
    categoryId: PropTypes.string,
    displayOrder: PropTypes.number,
    displayName: PropTypes.string,
    parentCategoryId: PropTypes.string
  }))
}));
export var memberProfilePropType = {
  authorized: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  ffn: PropTypes.string,
  authenticated: PropTypes.bool,
  points: PropTypes.number,
  openLoginModal: PropTypes.func,
  closeLoginModal: PropTypes.func,
  logout: PropTypes.func,
  visible: PropTypes.bool,
  flags: PropTypes.shape(),
  syncShoSession: PropTypes.func,
  showOptIn: PropTypes.bool,
  showNewUserModal: PropTypes.bool
};
export var memberProfileDefaultProps = {
  authorized: false,
  firstName: '',
  lastName: '',
  ffn: '',
  authenticated: false,
  points: 0,
  openLoginModal: function openLoginModal() {},
  closeLoginModal: function closeLoginModal() {},
  logout: function logout() {},
  visible: true,
  flags: {},
  syncShoSession: function syncShoSession() {},
  showOptIn: false,
  showNewUserModal: false
};
export var HOME_PAGE_SECTIONS = {
  ONLINE_MALL: 'ONLINE_MALL',
  CARD_OFFERS: 'CARD_OFFERS'
};
export var flagsPropType = PropTypes.shape({
  hasBonusPointsMsg: PropTypes.bool,
  isVisaEnabled: PropTypes.bool,
  isVisaAmexEnabled: PropTypes.bool
});
export var getFlagsFromProps = function getFlagsFromProps(flags) {
  return {
    hasBonusPointsMsg: getFeatureFlag({
      key: FEATURE_TOGGLE.BONUS_POINTS_MSG,
      flags: flags
    }),
    isVisaEnabled: getFeatureFlag({
      key: FEATURE_TOGGLE.VISA_ENROLMENT,
      flags: flags
    }),
    isVisaAmexEnabled: getFeatureFlag({
      key: FEATURE_TOGGLE.VISA_AMEX_ENROLMENT,
      flags: flags
    })
  };
};
export var featuredMerchantsPropType = PropTypes.arrayOf(PropTypes.shape({
  merchantId: PropTypes.string,
  merchantName: PropTypes.string,
  merchantLogo: PropTypes.string,
  merchantLogoSmall: PropTypes.string,
  rebate: PropTypes.shape(),
  is_elevated: PropTypes.bool,
  was_rebate: PropTypes.shape(),
  has_free_ship: PropTypes.bool,
  has_coupon_code: PropTypes.bool
}));