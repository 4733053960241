var __jsx = React.createElement;
/** @format */

import React from 'react';
import MediaQuery from 'react-responsive';
import { withRouter } from 'next/router';
import { responsiveQuery } from "../../constants/css";
import MobileStickyHeader from "./MobileStickyHeader";
import DesktopHeader from "./DesktopHeader";

/* eslint-disable react/no-danger */
var Header = function Header(_ref) {
  var showGlobalNav = _ref.showGlobalNav,
    currentPath = _ref.currentPath,
    router = _ref.router;
  return __jsx(React.Fragment, null, __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, __jsx(MobileStickyHeader, {
    currentPath: currentPath,
    router: router
  })), __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, __jsx(DesktopHeader, {
    showGlobalNav: showGlobalNav
  })));
};
Header.defaultProps = {
  showGlobalNav: true
};
export default withRouter(Header);