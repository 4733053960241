import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import { getShoSessionData } from "../utils/sessionHelper";
import reducer from "../reducers";
import { getLslSessionToken } from "../utils/loginHelper";
var initStore = function initStore() {
  var initialState = {};
  var shoSession = getShoSessionData();
  var lslSession = getLslSessionToken();
  initialState.user = {
    authorized: !!shoSession,
    lslSession: !!lslSession
  };
  if (shoSession) {
    var firstName = shoSession.firstName;
    initialState.user.firstName = firstName;
  }
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
};
export default initStore;