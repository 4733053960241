import moment from 'moment-timezone';
var CHAT_TIME_WINDOW = {
  weekdays: {
    start: 9,
    end: 19
  },
  timezone: 'Australia/Sydney'
};

// web chat config file with essentials
export var getChatConfig = function getChatConfig() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    webchatAppUrl: 'https://apps.mypurecloud.com.au/webchat',
    webchatServiceUrl: 'https://realtime.mypurecloud.com.au:443',
    orgId: 9154,
    orgName: 'qantasassure',
    queueName: data.queueName,
    locale: 'en',
    priority: 0,
    logLevel: 'ERROR',
    css: {
      width: '100%',
      height: '100%'
    },
    data: data
  };
};

/**
* Time ranges are INCLUSIVE
*/
var isValidTimeRange = function isValidTimeRange(timeRange, time) {
  return time.hour() >= timeRange.start && time.hour() <= timeRange.end;
};
export var isChatAvailable = function isChatAvailable() {
  var time = moment.tz(CHAT_TIME_WINDOW.timezone);
  var dayOfWeek = parseInt(moment(time).format('E'), 10);
  if (dayOfWeek >= 1 && dayOfWeek <= 5) {
    return isValidTimeRange(CHAT_TIME_WINDOW.weekdays, time);
  }
  return false;
};