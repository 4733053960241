import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { layoutSizes, colours } from "../../constants/css";
var ArrowPrev = styled.div.withConfig({
  displayName: "ArrowCarousel__ArrowPrev",
  componentId: "sc-xkpina-0"
})(["display:none;@media only screen and (min-width:", "){display:block;background:", ";height:50px;width:50px;border-radius:50%;position:absolute;top:40%;padding:0;cursor:pointer;left:1.25rem;z-index:1;background-image:url('static/slick/Icon-Arrow-right.svg');background-repeat:no-repeat;-webkit-transform:rotate(90deg);transform:rotate(90deg);background-position:center;}"], layoutSizes.desktopWidth, colours.qantasGrey);
var ArrowNext = styled(ArrowPrev).withConfig({
  displayName: "ArrowCarousel__ArrowNext",
  componentId: "sc-xkpina-1"
})(["@media only screen and (min-width:", "){right:1.25rem;left:auto;-webkit-transform:rotate(-90deg);transform:rotate(-90deg);}"], layoutSizes.desktopWidth);
var ArrowCarousel = function ArrowCarousel(_ref) {
  var type = _ref.type,
    onClick = _ref.onClick;
  if (type.toLowerCase() === 'next') {
    return __jsx(ArrowNext, {
      onClick: onClick
    });
  } else if (type.toLowerCase() === 'prev') {
    return __jsx(ArrowPrev, {
      onClick: onClick
    });
  }
  return null;
};
export default ArrowCarousel;