import React from "react";
var __jsx = React.createElement;
var logo = "/static/logos/Qantas-logo-notxt.svg";
import { Container } from "../_global";
import { NavWrapper, StyledFooter, StyledIcon, FollowTitle, Logo, SocialWrapper, TermsList, SocialNav } from "./style";
var Icon = function Icon(props) {
  return __jsx(StyledIcon, {
    href: props.href,
    "aria-label": "Link to ".concat(props.title),
    title: props.title,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx("span", {
    className: "sprite sprite-icon-".concat(props.title),
    "aria-hidden": "true"
  }), __jsx("span", {
    className: "sr-only"
  }, props.title));
};
var Footer = function Footer() {
  return __jsx(NavWrapper, null, __jsx(StyledFooter, null, __jsx(Container, {
    veryWideView: true
  }, __jsx(SocialNav, null, __jsx("a", {
    href: "https://www.qantas.com",
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx(Logo, {
    src: logo,
    alt: "Qantas logo"
  })), __jsx(FollowTitle, null, "Follow Us"), __jsx(SocialWrapper, null, __jsx("ul", {
    className: "list-unstyled"
  }, __jsx("li", null, __jsx(Icon, {
    href: "https://www.facebook.com/Qantas",
    title: "facebook"
  })), __jsx("li", null, __jsx(Icon, {
    href: "https://twitter.com/Qantas",
    title: "twitter"
  })), __jsx("li", null, __jsx(Icon, {
    href: "https://www.linkedin.com/company/qantas",
    title: "linkedin"
  })), __jsx("li", null, __jsx(Icon, {
    href: "https://www.youtube.com/user/Qantas",
    title: "youtube"
  })), __jsx("li", null, __jsx(Icon, {
    href: "https://instagram.com/Qantas/",
    title: "instagram"
  }))))), __jsx("div", null, __jsx(TermsList, null, __jsx("li", null, __jsx("a", {
    href: "https://www.qantas.com/au/en/support/terms-of-use.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Qantas Terms of Use")), __jsx("li", null, __jsx("a", {
    href: "https://www.qantas.com/au/en/support/privacy-and-security.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Privacy and Security")), __jsx("li", null, __jsx("a", {
    href: ""
  }, "\xA9 Qantas Airways Ltd ABN 16 009 661 901")))))));
};
Icon.defaultProps = {
  href: '#'
};
export default Footer;