import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { colours } from "../../constants/css";
var SpinnerContainer = styled.div.withConfig({
  displayName: "RingSpinner__SpinnerContainer",
  componentId: "sc-1cbf0e5-0"
})(["display:flex;align-items:center;justify-content:center;background:", ";width:95px;height:95px;border-radius:50%;margin:0 auto 1.5rem;"], colours.qantasWhite);
var Spinner = styled.div.withConfig({
  displayName: "RingSpinner__Spinner",
  componentId: "sc-1cbf0e5-1"
})(["position:relative;width:64px;height:64px;margin:0 auto;"]);
var Ring = styled.div.withConfig({
  displayName: "RingSpinner__Ring",
  componentId: "sc-1cbf0e5-2"
})(["box-sizing:border-box;display:block;position:absolute;width:80%;height:80%;margin:6px;border:3px solid ", ";border-radius:50%;animation:spin 1.2s cubic-bezier(0.5,0,0.5,1) infinite;border-color:", " transparent transparent transparent;"], colours.qantasRed, colours.qantasRed);
var Rings = styled.div.withConfig({
  displayName: "RingSpinner__Rings",
  componentId: "sc-1cbf0e5-3"
})(["display:inline-block;position:relative;width:100%;height:100%;transform:translate(-50%,-50%);top:50%;left:50%;z-index:1;", ":nth-child(1){animation-delay:-0.45s;}", ":nth-child(2){animation-delay:-0.3s;}", ":nth-child(3){animation-delay:-0.15s;}", ":nth-child(4){border-color:#E5E5E5;z-index:-1;}@keyframes spin{0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}}"], Ring, Ring, Ring, Ring);
var RingSpinner = function RingSpinner() {
  return __jsx(SpinnerContainer, null, __jsx(Spinner, null, __jsx(Rings, null, __jsx(Ring, null), __jsx(Ring, null), __jsx(Ring, null), __jsx(Ring, null))));
};
export default RingSpinner;