import { colours, customFonts, fontSizes, layoutSizes, responsiveQuery } from "../../../constants/css";
import styled from 'styled-components';
import Container from "../../_global/Container";
import Button from "../../_global/Button";
var tick = "/static/icons/icon_tick_blue.png";
var delay = "/static/icons/icon_7days.svg";
var email = "/static/icons/icon_email.svg";
var error = "/static/icons/ico_error.svg";
var dollar = "/static/icons/dollar-icon.svg";
export var Wrapper = styled.div.withConfig({
  displayName: "style__Wrapper",
  componentId: "sc-1ck16yz-0"
})(["width:100%;text-align:center;"]);
export var FrameWrapper = styled.div.withConfig({
  displayName: "style__FrameWrapper",
  componentId: "sc-1ck16yz-1"
})(["background-color:", ";"], colours.qantasIceWhite);
export var IFrame = styled.iframe.withConfig({
  displayName: "style__IFrame",
  componentId: "sc-1ck16yz-2"
})(["width:100%;min-height:860px !important;overflow:hidden;border:none;"]);
export var Terms = styled(Container).withConfig({
  displayName: "style__Terms",
  componentId: "sc-1ck16yz-3"
})(["padding:1rem;text-align:left;color:", ";width:100%;font-size:", ";ol{padding-left:18px;li{line-height:19px;margin-bottom:0.2rem;}}h4{margin-bottom:0.2rem;}a{color:", ";}"], colours.qantasTermsGrey, fontSizes.xs, colours.qantasTermsGrey);
export var ResultWrapper = styled.div.withConfig({
  displayName: "style__ResultWrapper",
  componentId: "sc-1ck16yz-4"
})(["padding:1rem;"]);
export var MoreCards = styled.div.withConfig({
  displayName: "style__MoreCards",
  componentId: "sc-1ck16yz-5"
})(["padding-bottom:1.5rem;text-align:left;max-width:350px;margin:auto;@media only screen and (min-width:", "){max-width:365px;}h4{font-family:", ";font-weight:normal;margin-bottom:0;margin-top:0.5rem;}"], layoutSizes.desktopWidth, customFonts.ciutadellaMed);
export var LinkButton = styled(Button).withConfig({
  displayName: "style__LinkButton",
  componentId: "sc-1ck16yz-6"
})(["width:100%;margin:0.625rem auto;"]);
var Box = styled.div.withConfig({
  displayName: "style__Box",
  componentId: "sc-1ck16yz-7"
})(["margin:auto;padding:1rem 2rem;border-radius:4px;box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 15px 14px 0 rgba(0,0,0,0.05);background-color:", ";font-size:", ";"], colours.qantasWhite, fontSizes.small);
export var ErrorIcon = styled.i.withConfig({
  displayName: "style__ErrorIcon",
  componentId: "sc-1ck16yz-8"
})(["display:block;height:35px;width:40px;background:url('", "') no-repeat;background-size:contain;"], error);
export var ErrorText = styled.div.withConfig({
  displayName: "style__ErrorText",
  componentId: "sc-1ck16yz-9"
})(["margin-top:0.5rem;font-family:", ";font-size:", ";"], customFonts.ciutadellaMed, fontSizes.large);
export var ErrorBox = styled(Box).withConfig({
  displayName: "style__ErrorBox",
  componentId: "sc-1ck16yz-10"
})(["max-width:300px;background-color:#f9f3e9;margin:1rem auto;i{margin:auto;}"]);
export var ErrorInfo = styled.p.withConfig({
  displayName: "style__ErrorInfo",
  componentId: "sc-1ck16yz-11"
})([""]);
export var StepsBox = styled(Box).withConfig({
  displayName: "style__StepsBox",
  componentId: "sc-1ck16yz-12"
})(["text-align:left;margin:1rem auto 1.5rem;max-width:350px;@media only screen and (min-width:", "){max-width:365px;}"], layoutSizes.desktopWidth);
export var SuccessHeading = styled.div.withConfig({
  displayName: "style__SuccessHeading",
  componentId: "sc-1ck16yz-13"
})(["max-width:350px;margin:auto;padding:1.5rem 0;@media only screen and (min-width:", "){max-width:365px;}p{font-family:", ";font-weight:normal;font-size:", ";text-align:left;}"], layoutSizes.desktopWidth, customFonts.ciutadellaMed, fontSizes.xl);
export var HeadWrapper = styled.div.withConfig({
  displayName: "style__HeadWrapper",
  componentId: "sc-1ck16yz-14"
})(["display:flex;align-items:center;p{margin:0;}"]);
export var TickIcon = styled.i.withConfig({
  displayName: "style__TickIcon",
  componentId: "sc-1ck16yz-15"
})(["height:32px;min-width:32px;background:url('", "') no-repeat;margin-right:10px;background-size:contain;"], tick);
export var DelayIcon = styled.i.withConfig({
  displayName: "style__DelayIcon",
  componentId: "sc-1ck16yz-16"
})(["display:inline-block;height:45px;min-width:31px;background:url('", "') no-repeat;margin-right:20px;background-position:center;margin-left:4px;"], delay);
export var EmailIcon = styled.i.withConfig({
  displayName: "style__EmailIcon",
  componentId: "sc-1ck16yz-17"
})(["display:inline-block;height:38px;min-width:38px;background:url('", "') no-repeat;margin-right:1rem;background-position:center;"], email);
export var DollarIcon = styled.i.withConfig({
  displayName: "style__DollarIcon",
  componentId: "sc-1ck16yz-18"
})(["display:inline-block;height:38px;min-width:38px;background:url('", "') no-repeat;margin-right:1rem;background-position:center;background-size:contain;"], dollar);
export var StepRow = styled.div.withConfig({
  displayName: "style__StepRow",
  componentId: "sc-1ck16yz-19"
})(["display:flex;align-items:center;"]);
export var StyledCardWrapper = styled.div.withConfig({
  displayName: "style__StyledCardWrapper",
  componentId: "sc-1ck16yz-20"
})(["position:relative;display:flex;justify-content:center;margin-bottom:1rem;"]);
export var InstructionsWrapper = styled.div.withConfig({
  displayName: "style__InstructionsWrapper",
  componentId: "sc-1ck16yz-21"
})(["margin:0 auto;padding-bottom:2rem;> div{text-align:left;margin-top:13px;position:relative;&:before{content:'';display:block;width:0;height:0;position:absolute;border-left:10px solid transparent;border-top:10px solid transparent;border-bottom:10px solid white;border-right:10px solid transparent;left:49%;top:-20px;}}div.Mobile{max-width:300px;padding:18px 10px 0 20px;margin:0 auto;background-color:#fff;}div.Desktop{background-color:#fff;@media only screen and ", "{width:870px;display:flex;align-items:start;justify-content:center;}@media only screen and ", "{width:300px;}margin:0 auto;padding:18px 10px 0 20px;}.Desktop div{width:280px;}.Desktop .first{flex:0 280px}.Desktop .last{flex:0 280px}img.instructionsIcon{width:25px;vertical-align:middle;margin-left:15px;}img.instructions{width:261px;}}"], responsiveQuery.tabletAndDesktop, responsiveQuery.mobileAndTablet);
export var Loading = styled.div.withConfig({
  displayName: "style__Loading",
  componentId: "sc-1ck16yz-22"
})(["height:25vh;background:white;display:flex;align-items:center;"]);