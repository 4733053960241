import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import Link from 'next/link';
import { CardCallout } from "../_global";
import EventTrackingHelper from "../../utils/trackingUtilities";
import useWindowAttributes from "../../utils/useWindowDimensions";
import { SearchResult, MerchantLogo, MoreArrow, MerchantName, CalloutWrapper, SearchSummary, SearchSummaryMobile } from "./style";
var SearchResults = function SearchResults(props) {
  var term = props.term,
    results = props.results,
    onResultClick = props.onResultClick,
    bonusRibbons = props.bonusRibbons;
  var _useWindowAttributes = useWindowAttributes(),
    isMobile = _useWindowAttributes.isMobile;
  var updatedBonusRibbonsForSearch = bonusRibbons.map(function (r) {
    return ['BONUS POINTS', 'DOUBLE POINTS', 'TRIPLE POINTS'].includes(r.name) ? _objectSpread(_objectSpread({}, r), {}, {
      name: r.name.split(' ')[0],
      title: isMobile ? r.title : r.title.split(' ')[0]
    }) : r;
  });
  return __jsx(React.Fragment, null, isMobile === true ? __jsx(SearchSummaryMobile, null, __jsx("span", {
    className: "result-number"
  }, results.length, " "), __jsx("span", {
    className: "static-text"
  }, "results found for \"", term, "\"")) : __jsx(SearchSummary, null, results.length, " results found for \"", term, "\""), results && results.map(function (merchant) {
    var merchantId = merchant.merchantId,
      merchantName = merchant.merchantName,
      merchantLogoSquare = merchant.merchantLogoSquare,
      calloutLabel = merchant.calloutLabel;
    return __jsx(SearchResult, {
      onClick: function onClick() {
        EventTrackingHelper.trackGenericAction("search|".concat(merchantName));
        onResultClick();
      },
      key: merchantId
    }, __jsx(Link, {
      href: "/merchant?slug=".concat(merchantId.replace(/\+/g, '%2B')),
      as: "/".concat(merchantId)
    }, __jsx("a", null, __jsx(MerchantLogo, {
      src: merchantLogoSquare
    }), __jsx(MerchantName, null, merchantName), __jsx(CalloutWrapper, null, calloutLabel && __jsx(CardCallout, {
      bonusRibbons: updatedBonusRibbonsForSearch,
      callType: calloutLabel.split(' ')[0],
      isSearch: !isMobile,
      isRightTop: isMobile
    }, calloutLabel), __jsx(MoreArrow, null)))));
  }));
};
SearchResults.defaultProps = {
  results: [],
  term: '',
  bonusRibbons: [],
  onResultClick: function onResultClick() {}
};
export default SearchResults;