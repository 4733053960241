var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colours } from "../../constants/css";
var ErrorPointer = styled.div.withConfig({
  displayName: "ErrorBox__ErrorPointer",
  componentId: "sc-19592lg-0"
})(["border:solid 10px transparent;border-bottom-color:", ";;transform:translate(57%,-33px);"], colours.qantasWarningHover);
var ErrorMsgContainer = styled.div.withConfig({
  displayName: "ErrorBox__ErrorMsgContainer",
  componentId: "sc-19592lg-1"
})(["width:100%;min-height:40px;background:", ";display:flex;justify-content:center;align-items:center;margin-bottom:10px;border:solid 0.5px rgba(151,151,151,0);"], colours.qantasWarningHover);
var ErrorBox = function ErrorBox(props) {
  return __jsx(ErrorMsgContainer, props, props.isArrow && __jsx(ErrorPointer, null), props.children);
};
ErrorBox.defaultProps = {
  isArrow: false
};
export default ErrorBox;