var __jsx = React.createElement;
import React from 'react';
var logo = "/static/logos/qantas-shopping-logo.png";
import styled from 'styled-components';
import { colours, customFonts, layoutSizes } from "../../constants/css";
import Container from "../_global/Container";
var outageImage = "/static/outage/maintenance-image-desktop.png";
var outageImageMob = "/static/outage/maintenance-image-mobbile.png";
import Head from 'next/head';
var OutageWrapper = styled.div.withConfig({
  displayName: "Outage__OutageWrapper",
  componentId: "sc-144pyt-0"
})(["color:", ";min-height:100vh;background-image:linear-gradient(216deg,rgba(255,255,255,0),#d4d4d4);"], colours.qantasCharcoal);
var Logo = styled.img.withConfig({
  displayName: "Outage__Logo",
  componentId: "sc-144pyt-1"
})(["max-width:350px;vertical-align:middle;margin-top:6rem;"]);
var PageTitle = styled.h1.withConfig({
  displayName: "Outage__PageTitle",
  componentId: "sc-144pyt-2"
})(["font-family:", ";font-size:1.875rem;color:", ";font-weight:normal;"], customFonts.ciutadellaMed, colours.qantasGrey);
var Signature = styled.p.withConfig({
  displayName: "Outage__Signature",
  componentId: "sc-144pyt-3"
})(["font-family:", ";font-size:1.3rem;"], customFonts.ciutadellaMed);
var CopyWrapper = styled.div.withConfig({
  displayName: "Outage__CopyWrapper",
  componentId: "sc-144pyt-4"
})(["max-width:400px;margin:auto;text-align:center;margin-bottom:4rem;"]);
var ImageWrapper = styled.p.withConfig({
  displayName: "Outage__ImageWrapper",
  componentId: "sc-144pyt-5"
})(["background:url(", ") no-repeat;min-height:171px;background-size:contain;background-position:center;@media only screen and (min-width:", "){background:url(", ") no-repeat;min-height:364px;background-position:left;}"], outageImageMob, layoutSizes.desktopWidth, outageImage);
var Outage = function Outage() {
  return __jsx(OutageWrapper, null, __jsx(Head, null, __jsx("title", null, "Maintenance | Qantas Shopping Earn")), __jsx(Container, null, __jsx(CopyWrapper, null, __jsx(Logo, {
    src: logo,
    alt: "Qantas Shopping Earn"
  }), __jsx(PageTitle, null, "We\u2019ll be back soon!"), __jsx("p", null, "Sorry for the inconvenience but we\u2019re performing some important maintenance. We\u2019ll be back online shortly!"), __jsx(Signature, null, "The Qantas Shopping Team"))), __jsx(ImageWrapper, null));
};
export default Outage;