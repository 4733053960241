/** @format */

import styled from 'styled-components';
import { colours, fontSizes, layoutSizes, zIndexes } from "../../constants/css";
var QffLogo = "/static/logos/qantas-shopping-logo-with-bar.svg";
var QffLogoMobile = "/static/logos/shopping-logo-mobile.png";
export var StyledHead = styled.header.withConfig({
  displayName: "style__StyledHead",
  componentId: "sc-xdph23-0"
})(["grid-area:header;font-size:", ";color:", ";.uni-header{display:none;@media only screen and (min-width:", "){display:block;}}"], fontSizes.base, colours.qantasCharcoal, layoutSizes.desktopWidth);
export var MobileHead = styled.header.withConfig({
  displayName: "style__MobileHead",
  componentId: "sc-xdph23-1"
})(["background:", ";position:fixed;top:0;left:0;width:100%;display:flex;padding-left:26px;z-index:", ";border-bottom:0.5px solid ", ";", ""], colours.qantasWhite, zIndexes.mobileHeader, colours.qantasBorderGrey, function (props) {
  return props.isMobileBrowser && "\n    padding: 0 0 0 1rem;\n  ";
});
export var MobileLogoLink = styled.a.withConfig({
  displayName: "style__MobileLogoLink",
  componentId: "sc-xdph23-2"
})(["flex:1;display:flex;justify-content:center;position:relative;margin:0 0 0 30px;", ""], function (props) {
  return props.isMobileBrowser && "\n    margin: 0;\n    justify-content: left;\n  ";
});
export var HeadContainer = styled.div.withConfig({
  displayName: "style__HeadContainer",
  componentId: "sc-xdph23-3"
})(["display:flex;justify-content:space-between;align-items:center;padding-left:1rem;height:60px;@media only screen and (min-width:", "){max-width:", ";margin:auto;}"], layoutSizes.desktopWidth, layoutSizes.containerVeryWideWidth);
export var Logo = styled.p.withConfig({
  displayName: "style__Logo",
  componentId: "sc-xdph23-4"
})(["height:28px;vertical-align:middle;background:url(", ") no-repeat;background-size:127px 28px;width:127px;cursor:pointer;image-rendering:-webkit-optimize-contrast;@media only screen and (min-width:", "){background:url(", ") no-repeat;background-size:202px 28px;width:202px;}"], QffLogoMobile, layoutSizes.desktopWidth, QffLogo);