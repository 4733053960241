var __jsx = React.createElement;
import * as React from 'react';
import { colours } from "../../../constants/css";
var CloseIcon = function CloseIcon(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color,
    viewBox = _ref.viewBox;
  return __jsx("svg", {
    width: width,
    height: height,
    viewBox: viewBox,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.848 6 .238 1.39A.815.815 0 1 1 1.392.24L6 4.848l4.61-4.61a.815.815 0 0 1 1.151 1.153L7.152 6l4.61 4.61a.815.815 0 0 1-1.153 1.151L6 7.152l-4.61 4.61A.815.815 0 0 1 .24 10.608L4.848 6Z",
    fill: color
  }));
};
CloseIcon.defaultProps = {
  width: '12px',
  height: '12px',
  viewBox: '0 0 12 12',
  color: colours.qantasWhite
};
export default CloseIcon;