export default function isLocalStorageEnabled() {
  var isLocalEnabled = false;
  try {
    if (typeof localStorage !== 'undefined') {
      var FEATURE_TEST_KEY = '__FEATURE_TEST__';
      var FEATURE_TEST_VALUE = 'Enabled';
      localStorage.setItem(FEATURE_TEST_KEY, FEATURE_TEST_VALUE);
      if (localStorage.getItem(FEATURE_TEST_KEY) === FEATURE_TEST_VALUE) {
        localStorage.removeItem(FEATURE_TEST_KEY);
        isLocalEnabled = true;
      }
    }
  } catch (e) {
    return false;
  }
  return isLocalEnabled;
}