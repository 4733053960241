import { useEffect, useState } from 'react';

// This is to get screen size as window does't exist in node env
var useWindowAttributes = function useWindowAttributes() {
  var _useState = useState({
      width: undefined,
      height: undefined,
      isMobile: undefined
    }),
    windowDimensions = _useState[0],
    setWindowDimensions = _useState[1];
  var _useState2 = useState({
      scrollY: undefined
    }),
    windowScroll = _useState2[0],
    setWindowScroll = _useState2[1];
  useEffect(function () {
    var handleResize = function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= 581
      });
    };
    var handleScroll = function handleScroll() {
      setWindowScroll({
        scrollY: window.scrollY
      });
    };
    handleResize();
    handleScroll();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return function () {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  var width = windowDimensions.width,
    height = windowDimensions.height,
    _windowDimensions$isM = windowDimensions.isMobile,
    isMobile = _windowDimensions$isM === void 0 ? undefined : _windowDimensions$isM;
  var scrollY = windowScroll.scrollY;
  return {
    width: width,
    height: height,
    isMobile: isMobile,
    scrollY: scrollY
  };
};
export default useWindowAttributes;