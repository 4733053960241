import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { layoutSizes } from "../../constants/css";
var Link = styled.a.withConfig({
  displayName: "TelLink__Link",
  componentId: "sc-qd93im-0"
})(["@media only screen and (min-width:", "){pointer-events:none;cursor:default;color:inherit;text-decoration:none;}"], layoutSizes.desktopWidth);
var TelLink = function TelLink(_ref) {
  var phoneNumber = _ref.phoneNumber;
  var href = "tel: ".concat(phoneNumber.replace(/\s+/g, ''));
  return __jsx(Link, {
    href: href,
    rel: "nofollow"
  }, phoneNumber);
};
export default TelLink;