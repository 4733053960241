var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { fontSizes, fontWeights, customFonts, lineHeights, responsiveQuery, letterSpacings } from "../../../constants/css";
import { ModalRevised as Modal, Button } from "./..";
var flagImg = "/static/logos/australia-flag.png";
var Container = styled.div.withConfig({
  displayName: "GeoNZIPModal__Container",
  componentId: "sc-16plxtm-0"
})(["display:flex;flex-direction:column;padding:2rem 1.5625rem;width:346px;@media only screen and ", "{padding:2rem;width:472px;}"], responsiveQuery.largerThanMobileNew);
var BannerWrapper = styled.div.withConfig({
  displayName: "GeoNZIPModal__BannerWrapper",
  componentId: "sc-16plxtm-1"
})(["display:flex;flex-direction:column;align-items:flex-start;margin-bottom:0.5rem;@media only screen and ", "{flex-direction:row;align-items:center;}"], responsiveQuery.largerThanMobileNew);
var BannerText = styled.p.withConfig({
  displayName: "GeoNZIPModal__BannerText",
  componentId: "sc-16plxtm-2"
})(["margin:0.5rem 0 0 0;font-size:", ";font-family:", ";font-weight:", ";line-height:", ";@media only screen and ", "{margin:0 0 0 1rem;}"], fontSizes.xl, customFonts.ciutadellaMed, fontWeights.semiBold, lineHeights.lh30, responsiveQuery.largerThanMobileNew);
var Text = styled.p.withConfig({
  displayName: "GeoNZIPModal__Text",
  componentId: "sc-16plxtm-3"
})(["margin:0 0 1.25rem 0;line-height:", ";"], lineHeights.lh24);
var ButtonsWrapper = styled.div.withConfig({
  displayName: "GeoNZIPModal__ButtonsWrapper",
  componentId: "sc-16plxtm-4"
})(["display:flex;"]);
var ButtonsCTA = styled(Button).withConfig({
  displayName: "GeoNZIPModal__ButtonsCTA",
  componentId: "sc-16plxtm-5"
})(["height:49px;width:144px;box-sizing:border-box;font-size:", ";font-weight:", ";letter-spacing:", ";@media only screen and ", "{width:152px;}"], fontSizes.base, fontWeights.semiBold, letterSpacings.ls1_5, responsiveQuery.largerThanMobileNew);
function GeoNZIPModal(_ref) {
  var show = _ref.show,
    onReject = _ref.onReject,
    onConfirm = _ref.onConfirm;
  return __jsx(Modal, {
    show: show,
    onClose: onReject
  }, __jsx(Container, null, __jsx(BannerWrapper, null, __jsx(Image, {
    height: 58,
    width: 103,
    src: flagImg
  }), __jsx(BannerText, null, "It looks like you\u2019re in New Zealand")), __jsx(Text, null, "Would you like to view the New Zealand Everyday Rewards page?"), __jsx(ButtonsWrapper, null, __jsx(ButtonsCTA, {
    primary: true,
    onClick: onConfirm
  }, "New Zealand"), __jsx(ButtonsCTA, {
    outline: true,
    marginLeft: true,
    onClick: onReject
  }, "Australia"))));
}
export default GeoNZIPModal;