import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import isBrowser from "../utils/isBrowser";
var SCROLL_DIRECTION = {
  UP: 2,
  DOWN: 1,
  UNKNOWN: 0
};
var idleScrollState = {
  isScrolling: false,
  isScrollingDown: false,
  isScrollingUp: false
};
function useScrollDirection() {
  var _useState = useState(idleScrollState),
    scrollState = _useState[0],
    setScroll = _useState[1];
  var prevScrollPos = isBrowser ? window.pageYOffset : 0;
  var prevScrollingDirection = SCROLL_DIRECTION.UNKNOWN;
  var stopScrollTimer;
  var updateScroll = function updateScroll() {
    // Stop propagation if user starts to scroll again within time frame
    if (stopScrollTimer) {
      clearTimeout(stopScrollTimer);
    }
    var currentScrollPos = window.pageYOffset;
    var currentScrollDirection = currentScrollPos > prevScrollPos ? SCROLL_DIRECTION.UP : SCROLL_DIRECTION.DOWN;
    if (currentScrollDirection !== prevScrollingDirection) {
      setScroll({
        isScrollingDown: currentScrollDirection === SCROLL_DIRECTION.UP,
        isScrollingUp: currentScrollDirection === SCROLL_DIRECTION.DOWN,
        isScrolling: true
      });
      prevScrollPos = currentScrollPos;
      prevScrollingDirection = currentScrollDirection;
    }
    stopScrollTimer = setTimeout(function () {
      // Browser has no native event for stop scroll
      prevScrollingDirection = SCROLL_DIRECTION.UNKNOWN;
      prevScrollPos = window.pageYOffset;
      setScroll(_objectSpread({}, idleScrollState));
    }, 300);
  };
  useEffect(function () {
    if (isBrowser) {
      var checkScroll = throttle(updateScroll, 200, {
        leading: true,
        trailing: false
      });
      window.addEventListener('scroll', checkScroll);
      return function () {
        if (stopScrollTimer) {
          clearTimeout(stopScrollTimer);
        }
        window.removeEventListener('scroll', checkScroll);
      };
    }
  }, []);
  return scrollState;
}
export default useScrollDirection;