import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["onPress", "pageLink", "asLink", "children", "loading"];
var __jsx = React.createElement;
/** @format */

import React from 'react';
import Router from 'next/router';
import styled, { keyframes, css } from 'styled-components';
import { colours, fontSizes, customFonts, layoutSizes } from "../../constants/css";
var ButtonContainer = styled.button.withConfig({
  displayName: "Button__ButtonContainer",
  componentId: "sc-10hf6ur-0"
})(["z-index:1;font-family:inherit;text-align:center;white-space:nowrap;vertical-align:middle;cursor:pointer;user-select:none;border:2px solid transparent;border-radius:0.25rem;padding:0.75rem 1rem;font-size:", ";border-radius:", ";transition:all 0.2s ease-in-out;text-transform:uppercase;letter-spacing:2px;position:relative;line-height:1.4;font-family:", ";", " ", " ", " ", " ", " ", " ", " ", ""], fontSizes.small, function (props) {
  return props.rounded ? '1.375rem' : '0.25rem';
}, customFonts.ciutadellaMed, function (props) {
  return props.primary && css(["background:", ";color:", ";&:hover,&:active{background-color:", ";border-color:", ";}"], colours.qantasRed, colours.qantasWhite, colours.qantasRedEarth, colours.qantasRedEarth);
}, function (props) {
  return props.outline && css(["background:", ";color:", ";border-color:currentColor;img{transition:all 0.2s ease-in-out;filter:invert(17%) sepia(88%) saturate(3085%) hue-rotate(352deg) brightness(94%) contrast(125%);}&:hover,&:active{background-color:", ";border-color:", ";color:", ";img{filter:invert(100%) sepia(11%) saturate(334%) hue-rotate(269deg) brightness(114%) contrast(100%);}}"], colours.qantasWhite, props.secondary ? colours.qantasCharcoal : colours.qantasRed, props.secondary ? colours.qantasCharcoal : colours.qantasRed, props.secondary ? colours.qantasCharcoal : colours.qantasRed, colours.qantasWhite);
}, function (props) {
  return props.disabled && css(["cursor:not-allowed;opacity:1;background-color:", ";color:", ";border:2px solid ", ";&:hover,&:active{background-color:", ";color:", ";border:2px solid ", ";}"], colours.qantasLightGrey, colours.qantasCharcoal, colours.qantasLightGrey, colours.qantasLightGrey, colours.qantasCharcoal, colours.qantasLightGrey);
}, function (props) {
  return props.link && css(["background-color:transparent;text-decoration:none;"]);
}, function (props) {
  return props.block && css(["width:100%;max-width:100%;"]);
}, function (props) {
  return props.narrow && css(["height:auto;@media only screen and (min-width:", "){padding:0 1rem;height:33px;}"], layoutSizes.desktopWidth);
}, function (props) {
  return props.large && css(["width:348px;max-width:90%;"]);
}, function (props) {
  return props.marginLeft && css(["margin:0 0 0 0.5rem;"]);
});
var rotate360 = keyframes(["from{transform:rotate(0deg);}to{transform:rotate(360deg);}"]);
var ButtonLoader = styled.span.withConfig({
  displayName: "Button__ButtonLoader",
  componentId: "sc-10hf6ur-1"
})(["display:inline-block;position:absolute;right:0.75rem;top:0.75rem;border:0.125rem solid rgba(255,255,255,0.2);border-left-color:", ";-webkit-animation:", " 1.1s infinite linear;animation:", " 1.1s infinite linear;border-radius:50%;width:18px;height:18px;vertical-align:middle;"], colours.qantasWhite, rotate360, rotate360);
var routeToPage = function routeToPage(pageLink, asLink) {
  return (asLink && Router.push(pageLink, asLink) || Router.push(pageLink)).then(function () {
    return window.scrollTo(0, 0);
  });
};
var Button = function Button(props) {
  var onPress = props.onPress,
    pageLink = props.pageLink,
    asLink = props.asLink,
    children = props.children,
    loading = props.loading,
    buttonProps = _objectWithoutProperties(props, _excluded);
  var onClick = function onClick() {
    // onPress will always be fired regardless of pageLink
    onPress === null || onPress === void 0 ? void 0 : onPress();
    pageLink && routeToPage(pageLink, asLink);
  };
  return __jsx(ButtonContainer, _extends({
    onClick: onClick
  }, buttonProps), children, loading && __jsx(ButtonLoader, null));
};
Button.defaultProps = {
  loading: false,
  onPress: function onPress() {
    return true;
  },
  pageLink: '',
  asLink: ''
};
export default Button;