import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colours } from "../../constants/css";
import Link from 'next/link';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { getBonusFavouritesCalloutCookie, setBonusFavouritesCalloutCookie } from "../../utils/sessionHelper";
import config from "../../utils/clientConfig";
var FavButton = styled.span.withConfig({
  displayName: "FavouriteNav__FavButton",
  componentId: "sc-ly3u20-0"
})(["padding:4px 6px;cursor:pointer;white-space:nowrap;position:relative;margin-left:auto;&:hover{#circle{stroke:", ";}#heart{fill:", ";}}svg{vertical-align:middle;width:30px;}.favouriteCallout{top:3em;left:-12em;width:15em;height:5em;background-color:", ";border-radius:6px;padding:5px 0;position:absolute;z-index:0;margin-left:0em;opacity:", ";transition:opacity 1s;padding:1em;}.text{white-space:normal;font-weight:bold;}}.favouriteCallout::after{content:\"\";position:absolute;top:-23%;left:85%;margin-left:0px;border-width:9px;border-style:solid;border-color:transparent transparent ", " transparent;}"], colours.qantasRed, colours.qantasRed, colours.qantasLightCyan, function (props) {
  return !props.calloutText ? '0 !important' : 0;
}, colours.qantasLightCyan);
var CloseFavouriteCallout = styled.span.withConfig({
  displayName: "FavouriteNav__CloseFavouriteCallout",
  componentId: "sc-ly3u20-1"
})(["position:absolute;right:7px;top:3px;font-weight:bold;"]);
var checkFavourites = function checkFavourites(_ref) {
  var favourites = _ref.favourites,
    asPath = _ref.asPath,
    bonusFavourites = _ref.bonusFavourites;
  if (favourites === undefined || favourites === null) {
    // should be logged in and have favourites defined
    return {
      withDot: false,
      calloutText: ''
    };
  }
  if (asPath !== '/' && !String(asPath).toLowerCase().startsWith('/offers')) {
    // if not on homepage or offers/campaign page - show dot without callouts
    return {
      withDot: true,
      calloutText: ''
    };
  }
  if (Object.values(favourites).filter(function (v) {
    return v;
  }).length === 0) {
    // callout page and no favourites
    return {
      withDot: true,
      calloutText: 'Favourite a brand and we’ll let you know when they’re on bonus'
    };
  }
  if ((bonusFavourites || []).length > 0) {
    // callout page and there are bonus favourites
    return {
      withDot: true,
      calloutText: 'Great news, one of your favourite brands is on bonus'
    };
  }
  return {
    // callout page, with favourites, but no bonus favourites
    withDot: false,
    calloutText: ''
  };
};
var FavouriteNav = function FavouriteNav(_ref2) {
  var authenticated = _ref2.authenticated,
    authorized = _ref2.authorized,
    favourites = _ref2.favourites,
    bonusFavourites = _ref2.bonusFavourites;
  var router = useRouter();
  var _useState = useState({
      calloutText: '',
      withDot: false,
      favClicked: false
    }),
    state = _useState[0],
    setStateBase = _useState[1];
  var setState = function setState(newState) {
    setStateBase(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), newState);
    });
  };
  useEffect(function () {
    var updateState = function updateState() {
      if (!authenticated || !authorized || state.favClicked) {
        setState({
          calloutText: '',
          withDot: false
        });
        return;
      }
      var _checkFavourites = checkFavourites({
          favourites: favourites,
          asPath: router.asPath,
          bonusFavourites: bonusFavourites
        }),
        withDotSource = _checkFavourites.withDot,
        calloutTextSource = _checkFavourites.calloutText;
      setState({
        calloutText: calloutTextSource,
        withDot: withDotSource
      });
    };
    var showCallout = function showCallout() {
      if (state.calloutText !== '') {
        var calloutCookie = getBonusFavouritesCalloutCookie();
        if (calloutCookie === null) {
          var calloutDiv = document.querySelector('.favouriteCallout');
          if (calloutDiv) {
            calloutDiv.style.opacity = 1;
            calloutDiv.style.zIndex = 100;
            setTimeout(function () {
              calloutDiv.style.opacity = 0;
              calloutDiv.style.zIndex = 0;
            }, 5000);
          }
          var cookieExpiration = new Date();
          cookieExpiration.setDate(cookieExpiration.getDate() + 1);
          cookieExpiration.setHours(0);
          cookieExpiration.setMinutes(0);
          cookieExpiration.setSeconds(0);
          setBonusFavouritesCalloutCookie(cookieExpiration);
        }
      }
    };
    updateState();
    showCallout();
  }, [authenticated, authorized, favourites, bonusFavourites, router.asPath, state.favClicked, state.calloutText]);
  var heartClicked = function heartClicked() {
    setState({
      favClicked: true
    });
    document.querySelectorAll('#favDot').forEach(function (d) {
      return d.style.visibility = 'hidden';
    });
    document.querySelectorAll('.favouriteCallout').forEach(function (c) {
      return c.style.opacity = 0;
    });
  };
  var withDot = state.withDot,
    calloutText = state.calloutText;
  return __jsx(FavButton, {
    id: "favButton",
    calloutText: calloutText
  }, __jsx("div", {
    className: "favouriteCallout"
  }, __jsx("span", {
    id: "calloutText",
    className: "text"
  }, calloutText), __jsx(CloseFavouriteCallout, {
    onClick: function onClick(e) {
      setBonusFavouritesCalloutCookie(config.FAVOURITES_BLUEDOT_COOKIE_DAYS);
      e.target.parentNode.style.opacity = 0;
    }
  }, "x")), __jsx(Link, {
    href: "/favourites"
  }, __jsx("a", {
    title: "link to my favourites",
    onClick: function onClick() {
      return heartClicked();
    }
  }, __jsx("span", null, __jsx("svg", {
    version: "1.1",
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("g", {
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("g", {
    transform: "translate(-1161 -14)"
  }, __jsx("g", {
    transform: "translate(1162 15)"
  }, __jsx("path", {
    cy: "5",
    id: "circle",
    d: "m0 15c0-8.2843 6.7139-15 15-15 8.2843 0 15 6.7139 15 15 0 8.2843-6.7139 15-15 15-8.2843 0-15-6.7139-15-15z",
    stroke: "#323232"
  }), withDot ? __jsx("circle", {
    id: "favDot",
    cx: "22",
    cy: "3",
    r: "5",
    fill: colours.qantasAqua
  }) : null, withDot ? __jsx("circle", {
    id: "favDot",
    cx: "22",
    cy: "3",
    r: "2",
    fill: colours.qantasCharcoal
  }) : null, __jsx("path", {
    cy: "5",
    id: "heart",
    d: "m20.862 11.19c-0.73458-0.76762-1.7111-1.19-2.75-1.19-1.039 0-1.911 0.42241-2.6458 1.19l-0.46669 0.52426-0.46664-0.52426c-0.73462-0.76762-1.6068-1.19-2.6456-1.19-1.0388 0-2.0157 0.42241-2.7503 1.19-1.5166 1.5848-1.5166 4.1634 0 5.7477l5.4343 4.8784c0.088853 0.093192 0.19947 0.15025 0.31503 0.17222 0.038755 0.0079912 0.077732 0.011697 0.11678 0.011697 0.15373 0 0.30772-0.061266 0.42479-0.18391l5.4343-4.8784c1.5166-1.5842 1.5166-4.1628-3.69e-5 -5.7477z",
    fill: "#323232",
    fillRule: "nonzero"
  })))))))));
};
var mapStateToProps = function mapStateToProps(_ref3) {
  var _ref3$user = _ref3.user,
    authenticated = _ref3$user.authenticated,
    authorized = _ref3$user.authorized,
    favourites = _ref3$user.favourites,
    bonusFavourites = _ref3$user.bonusFavourites;
  return {
    authenticated: authenticated,
    authorized: authorized,
    favourites: favourites,
    bonusFavourites: bonusFavourites
  };
};
export default connect(mapStateToProps)(FavouriteNav);