var __jsx = React.createElement;
import React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
var FeatureFlag = function FeatureFlag(_ref) {
  var isFlagEnabled = _ref.isFlagEnabled,
    fallback = _ref.fallback,
    flag = _ref.flag,
    children = _ref.children;
  if (!isFlagEnabled && fallback) {
    return fallback;
  }
  return isFlagEnabled && __jsx(React.Fragment, null, children);
};
FeatureFlag.defaultProps = {
  isFlagEnabled: false,
  fallback: null
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    isFlagEnabled: state.flags[ownProps.flag] || false
  };
};
export default connect(mapStateToProps)(FeatureFlag);