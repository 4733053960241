var __jsx = React.createElement;
import React from 'react';
import { StyledSkipLinks } from "./style";
var SkipLinks = function SkipLinks() {
  return __jsx(StyledSkipLinks, null, __jsx("ul", {
    className: "skiplinks"
  }, __jsx("li", null, __jsx("a", {
    href: "#page_header"
  }, "Jump to header")), __jsx("li", null, __jsx("a", {
    href: "#page_content"
  }, "Jump to main content")), __jsx("li", null, __jsx("a", {
    href: "#page_footer"
  }, "Jump to footer"))));
};
export default SkipLinks;