var __jsx = React.createElement;
/** @format */

import React from 'react';
import styled from 'styled-components';
import { colours, zIndexes } from "../../constants/css";
export var ModalWrapper = styled.div.withConfig({
  displayName: "ModalRevised__ModalWrapper",
  componentId: "sc-tdueyf-0"
})(["display:", ";position:fixed;z-index:", ";top:0;left:0;width:100%;height:100%;background-color:", ";"], function (_ref) {
  var show = _ref.show;
  return show ? 'block' : 'none';
}, zIndexes.modal, colours.qantasModalGreyBg);
export var ModalBody = styled.div.withConfig({
  displayName: "ModalRevised__ModalBody",
  componentId: "sc-tdueyf-1"
})(["position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);background-color:", ";"], colours.qantasWhite);
function ModalRevised(_ref2) {
  var show = _ref2.show,
    onClose = _ref2.onClose,
    children = _ref2.children;
  return __jsx(ModalWrapper, {
    show: show,
    onClick: function onClick() {
      return onClose();
    }
  }, __jsx(ModalBody, {
    onClick: function onClick(e) {
      return e.stopPropagation();
    }
  }, children));
}
export default ModalRevised;