import { Button } from "../_global";
import styled from 'styled-components';
import { colours, customFonts, fontSizes, layoutSizes } from "../../constants/css";
export var ChatBoxWrapper = styled.div.withConfig({
  displayName: "style__ChatBoxWrapper",
  componentId: "sc-bl6asa-0"
})(["width:100%;max-width:355px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:fixed;right:0px;-webkit-transition:bottom .3s ease-out 0s;transition:bottom .3s ease-out 0s;z-index:110;background:", ";-webkit-box-shadow:0 0 70px 0 ", ";box-shadow:0 0 70px 0 ", ";border-radius:4px 0 0;border-right:none;overflow:hidden;bottom:", ";"], colours.qantasWhite, colours.webChatShadow, colours.webChatShadow, function (props) {
  return props.openChatBox ? '0' : '-420px';
});
export var ChatBoxHeader = styled.div.withConfig({
  displayName: "style__ChatBoxHeader",
  componentId: "sc-bl6asa-1"
})(["position:", ";background:", ";border-bottom:1px solid ", ";position:-webkit-sticky;position:sticky;top:0;overflow:auto;padding:0.625rem 1.5rem;z-index:1;width:100%;h2{font-weight:400;line-height:1.36;margin-bottom:20px;margin:0.3125rem 0 0;font-size:1.875rem;}"], function (props) {
  return props.openChatBox ? 'absolute' : 'sticky';
}, colours.qantasWhite, colours.qantasAquaqantasGrey);
export var CloseChatBox = styled.div.withConfig({
  displayName: "style__CloseChatBox",
  componentId: "sc-bl6asa-2"
})(["position:absolute;top:0.625rem;right:0.625rem;outline:none;cursor:pointer;width:45px;background:transparent;border:2px solid ", ";color:", ";text-align:center;font-weight:700;border-radius:0.25rem;min-height:44px;div{padding-top:0.625rem;}"], colours.qantasRedGradient, colours.qantasRedGradient);
export var ChatBoxBody = styled.div.withConfig({
  displayName: "style__ChatBoxBody",
  componentId: "sc-bl6asa-3"
})(["background-color:", ";position:relative;padding:25px 30px 40px;font-size:", ";letter-spacing:normal;line-height:1.5;margin-bottom:0;padding:24px 24px 30px;input{border:2px solid ", ";border-radius:0;background-color:", ";font-size:", ";height:48px;padding:0 15px;width:100%;}p{margin:0 0 1rem 0;}"], colours.qantasWhite, fontSizes.xl, colours.qantasAquaqantasGrey, colours.qantasWhite, fontSizes.xl);
export var StartButton = styled(Button).withConfig({
  displayName: "style__StartButton",
  componentId: "sc-bl6asa-4"
})(["margin:20px 0;"]);
export var ChatButton = styled.span.withConfig({
  displayName: "style__ChatButton",
  componentId: "sc-bl6asa-5"
})(["font-family:", ";color:", ";padding:5px;margin:0 auto;cursor:pointer;white-space:nowrap;&:hover{background-image:none;}span{img{vertical-align:bottom;height:23px;}}@media only screen and (min-width:", "){padding:0;margin:0 auto;}"], customFonts.ciutadellaMed, colours.qantasWhite, layoutSizes.desktopWidth);
export var ChatButtonText = styled.span.withConfig({
  displayName: "style__ChatButtonText",
  componentId: "sc-bl6asa-6"
})(["display:none;@media only screen and (min-width:", "){display:inline-block;margin-left:0.375rem;}"], layoutSizes.desktopWidth);