import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { colours } from "../../constants/css";
var Loading = styled.span.withConfig({
  displayName: "Spinner__Loading",
  componentId: "sc-96vlf5-0"
})(["display:inline-block;margin:0rem auto;text-indent:-9999em;width:2rem;height:2rem;border-radius:50%;background:", ";background:linear-gradient(to right,", " 10%,", " 42%);position:relative;animation:load 1s infinite linear;transform:translateZ(0);&:before{width:50%;height:50%;background:", ";border-radius:100% 0 0 0;position:absolute;top:0;left:0;content:'';}&:after{background:", ";width:75%;height:75%;border-radius:50%;content:'';margin:auto;position:absolute;top:0;left:0;bottom:0;right:0;}@keyframes load{0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}}"], colours.qantasWhite, colours.qantasRed, colours.qantasRed, colours.qantasWhite, colours.qantasWhite);
var Spinner = function Spinner(props) {
  return __jsx(Loading, props);
};
export default Spinner;