import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Markdown from 'react-markdown';
var CMSInfo = function CMSInfo(_ref) {
  var infoData = _ref.infoData;
  var getImportantInfo = infoData.getImportantInfo;
  return __jsx("div", null, __jsx(Markdown, null, getImportantInfo && getImportantInfo.copy));
};
var infoQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n {\n  getImportantInfo {\n    copy\n  }\n}\n"])));
export default graphql(infoQuery, {
  props: function props(_ref2) {
    var data = _ref2.data;
    return {
      infoData: data
    };
  },
  options: function options() {
    return {
      ssr: false
    };
  }
})(CMSInfo);