/** @format */

import { createInstance } from '@optimizely/react-sdk';
import config from "../utils/clientConfig";
var optimizelyClient = createInstance({
  sdkKey: config.OPTIMIZELY_SDK_KEY,
  logLevel: 'error'
});
export var isClientValid = function isClientValid() {
  return optimizelyClient.getOptimizelyConfig() !== null;
};
export default optimizelyClient;