var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import config from "../../utils/clientConfig";
import styled from 'styled-components';
import { layoutSizes, colours, customFonts, fontSizes } from "../../constants/css";
var LockIcon = "/static/icons/ico_lock.svg";
import { Container, Icon } from "../_global";
import ChatBoxButton from "../ChatBox/Button";
import MemberProfile from "./MemberProfile";
import { StyledHead, HeadContainer, Logo } from "./style";
var NavWrapper = styled.nav.withConfig({
  displayName: "LinkCard__NavWrapper",
  componentId: "sc-1axkt41-0"
})(["position:relative;background-color:", ";height:60px;> div{height:100%;padding:0;}@media only screen and (min-width:", "){> div{padding:0.5rem;}}"], colours.qantasCharcoal, layoutSizes.desktopWidth);
var NavContent = styled.div.withConfig({
  displayName: "LinkCard__NavContent",
  componentId: "sc-1axkt41-1"
})(["display:flex;justify-content:space-between;align-items:center;height:100%;"]);
var ContactInfo = styled.div.withConfig({
  displayName: "LinkCard__ContactInfo",
  componentId: "sc-1axkt41-2"
})(["display:flex;align-items:center;color:", ";text-align:right;> *{display:flex;flex-direction:column;justify-content:center;padding:0 0.625rem;border-right:1px solid #4F4F4F;height:30px;&:last-child{border-color:transparent;}@media only screen and (min-width:", "){padding:0 1rem;}}"], colours.qantasWhite, layoutSizes.desktopWidth);
var OpeningHours = styled.p.withConfig({
  displayName: "LinkCard__OpeningHours",
  componentId: "sc-1axkt41-3"
})(["font-size:10px;margin:0;@media only screen and (min-width:", "){font-size:", ";}"], layoutSizes.desktopWidth, fontSizes.xs);
var PhoneDetails = styled.p.withConfig({
  displayName: "LinkCard__PhoneDetails",
  componentId: "sc-1axkt41-4"
})(["font-family:", ";font-size:11px;margin:0;@media only screen and (min-width:", "){font-size:", ";}"], customFonts.ciutadellaMed, layoutSizes.desktopWidth, fontSizes.small);
var SecureBadge = styled.div.withConfig({
  displayName: "LinkCard__SecureBadge",
  componentId: "sc-1axkt41-5"
})(["display:inline-flex;align-items:center;font-family:", ";font-size:", ";> span{margin-top:6px;}&::after{content:\"\";display:inline-block;background:url('", "') no-repeat;background-size:cover;width:16px;height:21px;margin-left:9px;}"], customFonts.ciutadellaMed, fontSizes.xs, LockIcon);
var BackButton = styled.button.withConfig({
  displayName: "LinkCard__BackButton",
  componentId: "sc-1axkt41-6"
})(["display:flex;align-items:center;outline:none;background:transparent;color:", ";border:none;cursor:pointer;font-size:", ";text-transform:uppercase;> i{margin-right:0.5rem;}@media only screen and (min-width:", "){font-size:", ";}"], colours.qantasWhite, fontSizes.xs, layoutSizes.desktopWidth, fontSizes.small);
var TelLink = styled.a.withConfig({
  displayName: "LinkCard__TelLink",
  componentId: "sc-1axkt41-7"
})(["text-decoration:none;color:", ";@media only screen and (min-width:", "){pointer-events:none;cursor:default;}"], colours.qantasWhite, layoutSizes.desktopWidth);
var renderBackButton = function renderBackButton() {
  if (window && window.history.length > 1) {
    return __jsx(BackButton, {
      onClick: function onClick() {
        return window.history.back();
      }
    }, __jsx(Icon, {
      iconType: "arrowLeft",
      size: "xxs"
    }), "Back");
  }
  return null;
};

/* eslint-disable react/no-danger */
var LinkCardHeader = function LinkCardHeader(_ref) {
  var showGlobalNav = _ref.showGlobalNav,
    showChat = _ref.showChat;
  return __jsx(StyledHead, null, __jsx("div", {
    "data-widget-type": "global_features_navigation",
    className: "".concat(showGlobalNav ? '' : 'sr-only', " uni-header")
  }, __jsx("script", {
    defer: true,
    type: "qantas/widget",
    dangerouslySetInnerHTML: {
      __html: JSON.stringify(config.UNIVERSAL)
    }
  })), __jsx(HeadContainer, null, __jsx(Link, {
    href: "/"
  }, __jsx("a", null, __jsx(Logo, null))), __jsx(MemberProfile, null)), __jsx(NavWrapper, null, __jsx(Container, {
    wideView: true
  }, __jsx(NavContent, null, __jsx("div", null, process.browser && renderBackButton()), __jsx(ContactInfo, null, __jsx("div", null, __jsx(PhoneDetails, null, "Need help?\xA0", __jsx(TelLink, {
    href: "tel: 134906",
    rel: "nofollow"
  }, "13 49 06")), __jsx(OpeningHours, null, "Monday - Friday 9am-7pm AEST")), __jsx("div", null, showChat && __jsx(ChatBoxButton, null)), __jsx("div", null, __jsx(SecureBadge, null, __jsx("span", null, "SECURE"))))))));
};
LinkCardHeader.defaultProps = {
  showGlobalNav: true,
  showChat: true
};
export default LinkCardHeader;