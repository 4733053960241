var __jsx = React.createElement;
import React from 'react';
var Heart = function Heart(_ref) {
  var isFilled = _ref.isFilled;
  return __jsx("svg", {
    width: "16px",
    height: "14px",
    viewBox: "-2 0 20 16",
    version: "1.1"
  }, __jsx("g", {
    stroke: isFilled ? 'none' : 'currentColor',
    strokeWidth: "2",
    fill: "none"
  }, __jsx("g", {
    transform: "translate(-7.000000, -9.000000)",
    fill: isFilled ? 'currentColor' : 'none',
    fillRule: "nonzero"
  }, __jsx("g", null, __jsx("path", {
    d: "M22.489896,10.957341 C21.6140518,10.0421071 20.4497248,9.53846154 19.2110417,9.53846154 C17.9721823,9.53846154 16.9325628,10.0421071 16.0564103,10.957341 L15.4999697,11.5824176 L14.9435897,10.957341 C14.0677015,10.0421071 13.0277572,9.53846154 11.7891621,9.53846154 C10.550611,9.53846154 9.38579953,10.0421071 8.50999942,10.957341 C6.70179507,12.8469555 6.70179507,15.9214398 8.50999942,17.8103179 L14.9892992,23.6268733 C15.0952392,23.7379865 15.2271246,23.8060169 15.3649127,23.8322072 C15.4111211,23.8417351 15.4575937,23.8461538 15.5041545,23.8461538 C15.6874461,23.8461538 15.8710461,23.7731064 16.0106402,23.6268733 L22.48994,17.8103179 C24.2982325,15.9214398 24.2982325,12.8469555 22.489896,10.957341 Z"
  })))));
};
Heart.defaultProps = {
  isFilled: false
};
export default Heart;