var __jsx = React.createElement;
/** @format */

import React from 'react';
import { memberProfileDefaultProps, memberProfilePropType } from "../../../constants/app";
import { Button, Section, ProfileLoginWrapper, MessageWrapper } from "./style";
import withMemberProfile from "../withMemberProfile";
var MemberProfileMobile = function MemberProfileMobile(_ref) {
  var openLoginModal = _ref.openLoginModal,
    toggleSideMenu = _ref.toggleSideMenu,
    authenticated = _ref.authenticated,
    authorized = _ref.authorized,
    firstName = _ref.firstName,
    logout = _ref.logout,
    removeAndSyncShoSession = _ref.removeAndSyncShoSession;
  var handleLoginClick = function handleLoginClick() {
    toggleSideMenu();
    openLoginModal(window.location.href);
    removeAndSyncShoSession();
  };
  var handleLogoutClick = function handleLogoutClick() {
    window.location.href = '/logout';
  };
  return __jsx(Section, null, !authenticated && !authorized && __jsx(Button, {
    onClick: handleLoginClick
  }, "LOG IN"), authorized && __jsx(ProfileLoginWrapper, null, firstName && __jsx("div", {
    className: "name"
  }, "Hi ", firstName), authorized && !authenticated && __jsx(MessageWrapper, null, "Not you?\xA0", __jsx("a", {
    onClick: handleLoginClick,
    role: "link"
  }, "Log in to your account")), authorized && authenticated && __jsx(MessageWrapper, {
    justifyContent: "space-between"
  }, "Welcome back", __jsx("a", {
    onClick: handleLogoutClick,
    role: "link"
  }, "Log out"))));
};
MemberProfileMobile.defaultProps = memberProfileDefaultProps;
export default withMemberProfile(MemberProfileMobile);