var __jsx = React.createElement;
/** @format */
import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import MediaQuery from 'react-responsive';
import { CHANNEL } from "../../constants/app";
import { responsiveQuery } from "../../constants/css";
import { Icon, ButtonTransparent } from "../_global";
var mobileSearchIcon = "/static/icons/mobile-search-icon-circle-white.svg";
var mobileSearchIconSelected = "/static/icons/mobile-search-icon-circle-grey.svg";
var menuIcon = "/static/icons/menu.svg";
var leftArrow = "/static/icons/mobile_header_left_arrow.svg";
import MobileSideNavbar from "../NavBar/MobileSideNavbar";
import EventTrackingHelper from "../../utils/trackingUtilities";
import Search from "../Search";
import { MobileNavbarContainer } from "../NavBar/style";
import { getMobileAppLandingPage, getChannel, isChannelForBrowser } from "../../utils/sessionStorageHelper";
import MemberProfile from "./MemberProfile";
import { MobileHead, Logo, MobileLogoLink } from "./style";

// currentPath: the path without query parameters
// router.asPath: the path with query parameters
var MobileStickyHeader = function MobileStickyHeader(_ref) {
  var currentPath = _ref.currentPath,
    router = _ref.router;
  var _useState = useState(false),
    isMenuOpen = _useState[0],
    setIsMenuOpen = _useState[1];
  var _useState2 = useState(false),
    isSearchOpen = _useState2[0],
    setIsSearchOpen = _useState2[1];
  var hideBackIcon = function hideBackIcon() {
    return currentPath === '/' || getMobileAppLandingPage() === router.asPath || getChannel() === CHANNEL.SHO_WEB;
  };
  var handleClick = function handleClick() {
    router.back();
  };
  var handleKeypress = function handleKeypress(e) {
    // it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleClick();
    }
  };
  var toggleSideMenu = function toggleSideMenu() {
    var isOpen = !isMenuOpen;
    var action = isOpen ? 'add' : 'remove';
    document.body.classList[action]('menu-opened');
    setIsMenuOpen(isOpen);
  };
  var isBrowser = isChannelForBrowser();
  var wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(function () {
      function handleClickOutside(event) {
        if (event.target.alt === "search") {
          if (event.target.src.includes('grey')) {
            setIsSearchOpen(false);
          } else {
            setIsSearchOpen(true);
          }
        } else if (ref.current && !ref.current.contains(event.target)) {
          setIsSearchOpen(false);
        }
      }
      document.addEventListener("touchstart", handleClickOutside);
      return function () {
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }, [ref]);
  }
  return __jsx(React.Fragment, null, __jsx(MobileHead, {
    isMobileBrowser: isBrowser
  }, !hideBackIcon() && __jsx(ButtonTransparent, {
    onKeyPress: handleKeypress,
    onClick: handleClick,
    "aria-label": "back"
  }, __jsx(Icon, {
    iconUrl: leftArrow,
    iconalt: "back",
    "aria-hidden": "true"
  })), __jsx(Link, {
    href: "/"
  }, __jsx(MobileLogoLink, {
    title: "link to home",
    isMobileBrowser: isBrowser
  }, __jsx(Logo, null))), __jsx(ButtonTransparent, {
    "aria-label": "menu",
    onClick: function onClick() {
      EventTrackingHelper.trackGenericAction('search');
    },
    padding: "0 6px 0 0",
    margin: isBrowser ? '0 0 0 10%' : '0'
  }, __jsx(Icon, {
    iconUrl: isSearchOpen ? mobileSearchIconSelected : mobileSearchIcon,
    iconalt: "search"
  })), __jsx(ButtonTransparent, {
    "aria-label": "menu",
    onClick: function onClick() {
      toggleSideMenu();
      EventTrackingHelper.trackGenericAction('hamburger');
    },
    padding: "0 15px",
    margin: "0"
  }, __jsx(Icon, {
    iconUrl: menuIcon,
    iconalt: "menu",
    "aria-hidden": "true"
  })), __jsx(MemberProfile, {
    isMobileBrowser: isBrowser
  })), __jsx(MobileSideNavbar, {
    isMenuOpen: isMenuOpen,
    toggleSideMenu: toggleSideMenu,
    currentPath: currentPath
  }), __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, __jsx(MobileNavbarContainer, {
    isSearchOpen: isSearchOpen,
    ref: wrapperRef
  }, __jsx(Search, {
    isMobile: true,
    onClearResults: function onClearResults() {
      return setIsSearchOpen(false);
    }
  }))));
};
export default MobileStickyHeader;