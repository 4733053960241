import styled from 'styled-components';
var TransparentButton = styled.button.withConfig({
  displayName: "ButtonTransparent__TransparentButton",
  componentId: "sc-ge89ib-0"
})(["padding:0;border:0;background-color:transparent;display:", ";margin:", ";padding:", ";position:", ";top:", ";right:", ";"], function (props) {
  return props.display;
}, function (props) {
  return props.margin;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.position;
}, function (props) {
  return props.top;
}, function (props) {
  return props.right;
});
export default TransparentButton;