import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { colours, fontSizes } from "../../constants/css";
import CloseIcon from "./Icons/CloseIcon";
var CloseBtnContainer = styled.button.withConfig({
  displayName: "CloseBtn__CloseBtnContainer",
  componentId: "sc-bgmmpb-0"
})(["float:right;font-size:", ";font-weight:500;position:absolute;top:0.75rem;right:0.75rem;outline:none;border:none;background:none;cursor:pointer;z-index:9999;"], fontSizes.large);
var CloseBtn = function CloseBtn(_ref) {
  var onClose = _ref.onClose;
  return __jsx(CloseBtnContainer, {
    onClick: onClose
  }, __jsx(CloseIcon, {
    width: "16px",
    height: "16px",
    color: colours.qantasBlack10
  }));
};
export default CloseBtn;