import { get } from 'lodash';
var isOfferActive = function isOfferActive(startDate) {
  if (startDate === null) {
    return true;
  }
  return new Date(startDate) <= new Date();
};
var isOfferExpired = function isOfferExpired(endDate) {
  if (endDate === null) {
    return false;
  }
  return new Date(endDate) <= new Date();
};
var isOfferValid = function isOfferValid(startDate, endDate) {
  return isOfferActive(startDate) && !isOfferExpired(endDate);
};
var assembleToasters = function assembleToasters(content) {
  return content.length > 0 ? content.filter(function (ctrl) {
    return get(ctrl, 'contentType', '') === 'toaster' && isOfferValid(ctrl.startDate, ctrl.endDate);
  }) : [];
};
export { isOfferActive, isOfferExpired, isOfferValid, assembleToasters };